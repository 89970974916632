import { TrillerInvite, TrillerInviteBotTypes } from './types';

export const getSlackMessageBody = ({ accountId, botId, platform, userId, accountName, botName, emailId, botType }: TrillerInvite) => {
  const slackBody = JSON.stringify({
    //eslint-disable-next-line @typescript-eslint/naming-convention
    Details: `\n *Account : * ${accountName} (${accountId}) \n *Bot :* ${botName} (${botId}) \n *Platform :* ${platform} \n*Template Used:* ${botType ||  TrillerInviteBotTypes.TrillerCommunity} \n *User :* ${emailId} (${userId})`,
  });
  return slackBody;
};

export const getTrillerLanguages = (trillerInvite: TrillerInvite | null) => {
  const trillerInviteLanguages = {
    [TrillerInviteBotTypes.TrillerCommunity]: {
      title: 'Welcome to Community by Triller!',
      inviteText: 'Triller has invited you to Kick Start your community.',
      inviteText2: "In order to start, you must set up credentials with Triller's Community and connect it to your Facebook business account.",
    },
    [TrillerInviteBotTypes.AmplifyTier2]: {
      title: 'Welcome to Amplify by Triller!',
      inviteText: `Amplify has invited you to launch your Smart Comment Responder.`,
      inviteText2: `In order to start, you must set up credentials with Amplify and connect it to your socials.`,
    },
  };
  return trillerInvite ? trillerInviteLanguages[trillerInvite?.botType || TrillerInviteBotTypes.TrillerCommunity] : null;
};
