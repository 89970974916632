import { createContext, useContext, useEffect, useState } from 'react';
import { TrillerInvite } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { getInviteDetails } from './async-actions/actions';
import { Dispatch, RootState } from 'src/types';
import { useLocation } from 'react-router-dom';
import { FBDeployPage, FbPage, FbPageList, SelectedPage } from '../fb-login/types';
import { useDeployBot } from 'src/hooks';
import { storeFbPages } from '../fb-login/actions';
import { fetchBotPlatformBotForAccount } from 'src/common/entities/actions/async-actions';
import { Bot } from 'src/common/entities/entities.types';
import { sendSlackAlert } from 'src/common/modal/actions/async-actions';
import { slackChannels } from '../constants';
import { getSlackMessageBody } from './utils';
import { platforms } from 'src/common/entities/entities.constants';

export const TrillerInviteContext = createContext<{
  inviteToken: string;
  trillerInvite: TrillerInvite | null;
  setTrillerInvite: React.Dispatch<React.SetStateAction<TrillerInvite | null>>;
  setInviteToken: React.Dispatch<React.SetStateAction<string>>;
  isInValidInvite: boolean;
  allFbPages?: FbPageList;
  onCheckboxChange: (fbPage: FbPage) => void;
  onDeployPage: () => void;
  selectedPages: {
    [key: string]: SelectedPage;
  };
  isValidatingInvite: boolean;
  igRedirectData?: {
    errorMessage?: string;
  };
  isLinkedSuccessfully: boolean;
  isAlreadyLinked: boolean;
}>({
  inviteToken: '',
  trillerInvite: null,
  setInviteToken: () => {},
  setTrillerInvite: () => {},
  isInValidInvite: false,
  onCheckboxChange: () => {},
  selectedPages: {},
  onDeployPage: () => {},
  isValidatingInvite: true,
  isLinkedSuccessfully: false,
  isAlreadyLinked: false,
});

export const TrillerInviteProvider = TrillerInviteContext.Provider;

//eslint-disable-next-line max-lines-per-function
export const useTrillerInvite = () => {
  const location = useLocation();
  const [isValidatingInvite, setIsValidatingInvite] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState<{ [key: string]: SelectedPage }>({});
  const { fbPageList: allFbPages } = useSelector((state: RootState) => state.fbDeploy) || {};
  const search = new URLSearchParams(location.search);
  const token = search.get('token') || '';
  const errMsgFromIG = search.get('errMsg');
  let igRedirectData: any = undefined;
  if (errMsgFromIG) {
    igRedirectData = {
      errorMessage: errMsgFromIG,
    };
  }
  const [trillerInvite, setTrillerInvite] = useState<TrillerInvite | null>(null);
  const [inviteToken, setInviteToken] = useState<string>(token);
  const [isInValidInvite, setIsInValidInvite] = useState<boolean>(false);
  const [isLinkedSuccessfully, setIsLinkedSuccessfully] = useState<boolean>(false);
  const [isAlreadyLinked, setIsAlreadyLinked] = useState<boolean>(false);
  const dispatch = useDispatch<Dispatch>();
  

  const onSuccessfullyLinked = async () => {
    dispatch(storeFbPages({}));
    setIsLinkedSuccessfully(true);
    if (trillerInvite) {
      await dispatch(
        sendSlackAlert('Triller Community: Invitation accepted successfully.', getSlackMessageBody(trillerInvite), slackChannels.TRILLER_COMMUNITY_SNS_TOPIC, undefined, true),
      );
    }
  };
  const deployBot = useDeployBot(onSuccessfullyLinked);

  const onCheckboxChange = (fbPage: FbPage) => {
    if (selectedPages[fbPage.id]) {
      setSelectedPages({});
    } else {
      setSelectedPages({ [fbPage.id]: { status: true } as SelectedPage });
    }
  };

  const fetchInvitationDetails = async () => {
    setIsValidatingInvite(true);
    const inviteDetails = await dispatch(getInviteDetails(inviteToken));
    if (inviteDetails) {
      const bots = await dispatch(fetchBotPlatformBotForAccount(inviteDetails.accountId, undefined, true));
      const bot = bots?.find((b: Bot) => b.id === inviteDetails.botId);
      if (bot) {
        if (Boolean(bot?.platformBots?.length)) {
          if (inviteDetails.platform === platforms.INSTAGRAM) {
            setIsLinkedSuccessfully(true);
          } else {
            setIsAlreadyLinked(true);
          }
        }
        setTrillerInvite(inviteDetails);
        if (igRedirectData?.errorMessage) {
          setIsInValidInvite(true);
        }
      } else {
        setIsInValidInvite(true);
      }
    } else {
      setIsInValidInvite(true);
    }
    setIsValidatingInvite(false);
  };

  const onDeployPage = () => {
    const selectedPageIds = Object.keys(selectedPages) || [];
    const fbPagesToDeploy: FBDeployPage[] = selectedPageIds.map((pId: string) => {
      const userPages = allFbPages?.currentUserPages || [];
      let deployPage: FBDeployPage = userPages.find((p: FbPage) => {
        const selectedPage = selectedPages[pId];
        if (p.id === pId && selectedPage && selectedPage.status) {
          return true;
        }
        return false;
      });
      if (deployPage && selectedPages[deployPage.id] && selectedPages[deployPage.id].isInstagramSelected) {
        deployPage = { ...deployPage };
        deployPage.instagramEnabled = true;
        deployPage.instagramAccountId = selectedPages[deployPage.id].instagramAccountId;
      }
      return deployPage;
    });
    if (fbPagesToDeploy.length && trillerInvite?.botId) {
      deployBot(fbPagesToDeploy, false, trillerInvite?.botId);
    }
  };

  useEffect(() => {
    if (inviteToken) {
      fetchInvitationDetails();
    } else {
      setIsInValidInvite(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteToken]);

  return {
    trillerInvite,
    setTrillerInvite,
    inviteToken,
    setInviteToken,
    isInValidInvite,
    allFbPages,
    onCheckboxChange,
    selectedPages,
    onDeployPage,
    isValidatingInvite,
    igRedirectData,
    isLinkedSuccessfully,
    isAlreadyLinked,
  };
};

export const useTillerInviteContext = () => useContext(TrillerInviteContext);
