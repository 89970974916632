import { platforms } from '../common/entities/entities.constants';

export const actions = {
  HIDE: 'hide',
  LIKE: 'like',
  PUBLIC_REPLY: 'public',
  PRIVATE_REPLY: 'privateReply',
  BLOCK: 'block',
  DELETE: 'delete',
  REVIEW: 'review',
  ASSIGN: 'assign',
};

export const actionsNotSupportedInMentions = {
  [platforms.INSTAGRAM]: [actions.DELETE],
  [platforms.TWITTER]: [actions.DELETE],
};
export const smsSupportedPlatforms = [platforms.TWILIO_SMS];
export const supportedPlatformsAudience = [platforms.TWILIO_SMS, platforms.WHATSAPP, platforms.SPRINKLR_APPLE];
export const subscriptionPlatformForAudienceExcludingSMS = [platforms.WHATSAPP, platforms.SPRINKLR_APPLE];
export const broadcastSupportedPlatforms = [platforms.TWILIO_SMS];
export const InstaFBPlatforms = [platforms.FACEBOOK, platforms.INSTAGRAM];
export const testUsingPhoneNumberBotPlatforms = smsSupportedPlatforms; //for now
export const twentyFourHourRestrictedBotPlatforms = [platforms.INSTAGRAM, platforms.FACEBOOK];
export const mmBroadcastSupportedPlatforms = InstaFBPlatforms;
