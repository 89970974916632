import { debounce } from 'lodash';
import { variableRegex } from '../../utils/regex';

export const commaSeparatedEmailRegex = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/;

export const isBlankField = (field: string | undefined) => {
  return field && field.length ? false : true;
};

export const isBlankorSpacedField = (field: string | undefined) => {
  return field && field.length && field.replaceAll(new RegExp(variableRegex.source, 'g'), '').indexOf(' ') === -1 ? false : true;
};

export const validateEmail = (email: string): boolean => {
  if (!email) {
    return false;
  }
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const validateMultipleEmail = (email: string = '') => {
  return commaSeparatedEmailRegex.test(email);
};
export const debounceSingleFieldValidation = debounce(
  ({ name, value, validator, callback }: { name: string; value: any; validator: (name: string, value: any) => string; callback: Function }) => {
    const errorMessage = validator(name, value);
    callback({ name, errorMessage });
  },
  500,
);
