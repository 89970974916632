import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import './header.scss';

const { Header: AntHeader } = Layout;

const Header: React.FC = () => {
  return (
    <AntHeader className='header'>
      <div className='logo-container'>
        <Link to='#' className='logo-link' aria-label='Go to homepage'>
          <img src={'images/Triller-Logo.svg'} alt='Triller Logo' className='logo' />
        </Link>
      </div>
    </AntHeader>
  );
};

export default Header;
