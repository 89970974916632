import { ActionReducerMapBuilder, combineReducers, createReducer } from '@reduxjs/toolkit';
import searchFilterReducer from '../components/search-filter/reducer';
import postFilterReducer from '../components/post-filter/reducer';
import {
  setCommentViewerCurrentTab,
  setCommentViewerEngagementsCurrentTab,
  updateDismissAction,
  indicateRecentCommentMessage,
  indicateSearchMessage,
  updateEngagementsSecondaryTabCounts,
  updateNonActionableSecondaryTabCounts,
  updatePrimaryTabCounts,
  updateStatsOnClose,
  setReplyModalModeAction,
  clearReplyMessageFromQuickReply,
  setOpenPreviewReplyModalAction,
  setReplyContent,
  setIncludeSendPrivateReplyButton,
  setPrivateMessageContent,
  setCreateTemplateName,
  setSelectedReplyTemplate,
  setMedia,
  setReplyType,
  setReplyTypePlusMode,
  setSelectedUserComment,
  setCommentsInSinglePage,
  setSelectedCommentPage,
  setPreviousCommentPage,
  setSelectAllFlag,
  setErrorSelectedComment,
  setGcForManualTrigger,
} from '../actions';
import constants from '../comments-viewer.constants';
import { Template } from '../../../../templates/templates.types';
import { MediaContainer } from '../comments-viewer.types';
import { Comment, RawComment } from '../components/comment/comment.types';

const initialState: {
  numberOfTotalComments: number;
  numberOfActionableComments: number;
  numberOfNonActionableComments: number;
  numberOfNonActionableLabeledComments: number;
  numberOfNonActionableUnlabeledComments: number;
  numberOfEngagements: number;
  numberOfCurrentUserAssignedComments: number;
  numberOfHiddenComments: number;
  numberOfBrandReactions: number;
  numberOfPublicReplies: number;
  numberOfPrivateReplies: number;
  numberOfBlockedUsers: number;
  numberOfDeletedComments: number;
  numberOfReviewedComments: number;
  numberOfAssignedComments: number;
  shouldUpdateStatsOnClose: boolean;
  currentTab: string;
  engagementsCurrentTab: string;
  indicateRecentCommentMessage: boolean;
  indicateSearchMessage: boolean;
  updateDismissAction: { commentDismiss: boolean; searchDismiss: boolean };
  replyModalMode: string | null;
  replyPrevModalMode: string | null;
  replyCommentId: string | null;
  replyMessage?: string;
  setOpenPreviewReplyModalAction: boolean;
  replyMessageContent: string;
  includeSendPrivateReplyButton: boolean;
  setPrivateMessageButtonContent: string;
  createTemplateName: string;
  selectedReplyTemplate: Template | null;
  attachedMedia: MediaContainer;
  replyTypePlusMode: string | null;
  replyType: string;
  selectedUserComment: RawComment[];
  commentsInSinglePage: Comment[];
  currentPage: number;
  previousCommentPage: number;
  isSelectedAll: boolean;
  campaignsForManualTrigger: {
    label: string;
    value: string;
  }[];
} = {
  numberOfTotalComments: 0,
  numberOfActionableComments: 0,
  numberOfNonActionableComments: 0,
  numberOfNonActionableLabeledComments: 0,
  numberOfNonActionableUnlabeledComments: 0,
  numberOfEngagements: 0,
  numberOfCurrentUserAssignedComments: 0,
  numberOfHiddenComments: 0,
  numberOfBrandReactions: 0,
  numberOfPublicReplies: 0,
  numberOfPrivateReplies: 0,
  numberOfBlockedUsers: 0,
  numberOfDeletedComments: 0,
  numberOfReviewedComments: 0,
  numberOfAssignedComments: 0,
  shouldUpdateStatsOnClose: false,
  currentTab: '',
  engagementsCurrentTab: '',
  indicateRecentCommentMessage: false,
  indicateSearchMessage: false,
  updateDismissAction: { commentDismiss: false, searchDismiss: false },
  replyModalMode: null,
  replyPrevModalMode: null,
  replyCommentId: '',
  setOpenPreviewReplyModalAction: false,
  replyMessageContent: '',
  includeSendPrivateReplyButton: false,
  setPrivateMessageButtonContent: '',
  createTemplateName: '',
  selectedReplyTemplate: null,
  attachedMedia: [
    {
      mediaURL: '',
      mediaType: '',
      mediaName: '',
    },
  ],
  replyTypePlusMode: null,
  replyType: '',
  selectedUserComment: [],
  commentsInSinglePage: [],
  currentPage: 1,
  previousCommentPage: 1,
  isSelectedAll: false,
  campaignsForManualTrigger: [],
};
/* eslint-disable max-lines-per-function */
const commentsViewerReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(updatePrimaryTabCounts, (state: typeof initialState, action: ReturnType<typeof updatePrimaryTabCounts>) => {
      state.numberOfTotalComments = action.payload.updatedTotalCommentsCount;
      state.numberOfActionableComments = action.payload.updatedActionableCommentsCount;
      state.numberOfNonActionableComments = action.payload.updatedNonActionableCommentsCount;
      state.numberOfEngagements = action.payload.updatedEngagementsCount;
      state.numberOfCurrentUserAssignedComments = action.payload.updatedCurrentUserAssignedCommentsCount;
    })
    .addCase(updateNonActionableSecondaryTabCounts, (state: typeof initialState, action: ReturnType<typeof updateNonActionableSecondaryTabCounts>) => {
      state.numberOfNonActionableLabeledComments = action.payload.updatedLabeledCommentsCount;
      state.numberOfNonActionableUnlabeledComments = action.payload.updatedUnlabeledCommentsCount;
    })
    .addCase(updateEngagementsSecondaryTabCounts, (state: typeof initialState, action: ReturnType<typeof updateEngagementsSecondaryTabCounts>) => {
      state.numberOfBrandReactions = action.payload.updatedBrandReactionsCount;
      state.numberOfDeletedComments = action.payload.updatedDeletedCommentsCount;
      state.numberOfHiddenComments = action.payload.updatedHiddenCommentsCount;
      state.numberOfPublicReplies = action.payload.updatedPublicRepliesCount;
      state.numberOfPrivateReplies = action.payload.updatedPrivateRepliesCount;
      state.numberOfBlockedUsers = action.payload.updatedBlockedUsersCount;
      state.numberOfReviewedComments = action.payload.updatedReviewedCommentsCount;
      state.numberOfAssignedComments = action.payload.updatedAssignedCommentsCount;
    })
    .addCase(updateStatsOnClose, (state: typeof initialState, action: ReturnType<typeof updateStatsOnClose>) => {
      state.shouldUpdateStatsOnClose = action.payload;
    })
    .addCase(setCommentViewerCurrentTab, (state: typeof initialState, action: ReturnType<typeof setCommentViewerCurrentTab>) => {
      state.currentTab = action.payload;
    })
    .addCase(setCommentViewerEngagementsCurrentTab, (state: typeof initialState, action: ReturnType<typeof setCommentViewerEngagementsCurrentTab>) => {
      state.engagementsCurrentTab = action.payload;
    })
    .addCase(indicateRecentCommentMessage, (state: typeof initialState, action: ReturnType<typeof indicateRecentCommentMessage>) => {
      state.indicateRecentCommentMessage = action.payload;
    })
    .addCase(indicateSearchMessage, (state: typeof initialState, action: ReturnType<typeof indicateSearchMessage>) => {
      state.indicateSearchMessage = action.payload;
    })
    .addCase(updateDismissAction, (state: typeof initialState, action: ReturnType<typeof updateDismissAction>) => {
      state.updateDismissAction = { ...state.updateDismissAction, ...action.payload };
    })
    .addCase(setReplyTypePlusMode, (state: typeof initialState, action: ReturnType<typeof setReplyTypePlusMode>) => {
      state.replyTypePlusMode = action.payload;
    })

    .addCase(setReplyModalModeAction, (state: typeof initialState, action: ReturnType<typeof setReplyModalModeAction>) => {
      state.replyModalMode = action.payload?.mode || null;
      state.replyCommentId = action.payload?.commentId || null;
      state.replyMessage = action.payload?.message;
      state.replyPrevModalMode = action.payload?.prevMode || null;
    })
    .addCase(clearReplyMessageFromQuickReply, (state: typeof initialState) => {
      state.replyMessage = undefined;
    })
    .addCase(setOpenPreviewReplyModalAction, (state: typeof initialState, action: ReturnType<typeof setOpenPreviewReplyModalAction>) => {
      state.setOpenPreviewReplyModalAction = action.payload;
    })
    .addCase(setReplyContent, (state: typeof initialState, action: ReturnType<typeof setReplyContent>) => {
      state.replyMessageContent = action.payload;
    })
    .addCase(setIncludeSendPrivateReplyButton, (state: typeof initialState, action: ReturnType<typeof setIncludeSendPrivateReplyButton>) => {
      state.includeSendPrivateReplyButton = action.payload;
    })
    .addCase(setPrivateMessageContent, (state: typeof initialState, action: ReturnType<typeof setPrivateMessageContent>) => {
      state.setPrivateMessageButtonContent = action.payload;
    })
    .addCase(setCreateTemplateName, (state: typeof initialState, action: ReturnType<typeof setPrivateMessageContent>) => {
      state.createTemplateName = action.payload;
    })
    .addCase(setSelectedReplyTemplate, (state: typeof initialState, action: ReturnType<typeof setSelectedReplyTemplate>) => {
      state.selectedReplyTemplate = action.payload;
    })
    .addCase(setMedia, (state: typeof initialState, action: ReturnType<typeof setMedia>) => {
      state.attachedMedia = action.payload;
    })
    .addCase(setReplyType, (state: typeof initialState, action: ReturnType<typeof setReplyType>) => {
      state.replyType = action.payload;
    })
    .addCase(setSelectedUserComment, (state: typeof initialState, action: ReturnType<typeof setSelectedUserComment>) => {
      state.selectedUserComment = action.payload;
    })
    .addCase(setCommentsInSinglePage, (state: typeof initialState, action: ReturnType<typeof setCommentsInSinglePage>) => {
      state.commentsInSinglePage = action.payload;
    })
    .addCase(setSelectedCommentPage, (state: typeof initialState, action: ReturnType<typeof setSelectedCommentPage>) => {
      state.currentPage = action.payload;
    })
    .addCase(setPreviousCommentPage, (state: typeof initialState, action: ReturnType<typeof setPreviousCommentPage>) => {
      state.previousCommentPage = action.payload;
    })
    .addCase(setSelectAllFlag, (state: typeof initialState, action: ReturnType<typeof setSelectAllFlag>) => {
      state.isSelectedAll = action.payload;
    })
    .addCase(setErrorSelectedComment, (state: typeof initialState, action: ReturnType<typeof setErrorSelectedComment>) => {
      if (state.selectedUserComment) {
        const { userSelectedComments } = action.payload;
        state.selectedUserComment = userSelectedComments;
      }
    })
    .addCase(setGcForManualTrigger, (state: typeof initialState, action: ReturnType<typeof setGcForManualTrigger>) => {
      state.campaignsForManualTrigger = action.payload;
    });
});

export default combineReducers({
  comments: commentsViewerReducer,
  searchFilter: searchFilterReducer(constants.COMMENTS_VIEWER),
  postFilter: postFilterReducer(constants.COMMENTS_VIEWER),
});
