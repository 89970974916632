export type FetchCommentsApiResponse = {
  [key: string]: unknown;
}[];
export type MediaContainer = [
  {
    mediaURL: string;
    mediaType: string;
    mediaName: string;
  },
];

export type SetFilterToStorageAndRedux = {
  page: string;
  key: string;
  storeInLocalStorage?: boolean;
};

export type CommentReceivedSubGroup = {
  key: string;
  commentsReceived: number;
};

export type FetchPostDetailsResponse = {
  subGroups: CommentReceivedSubGroup[];
  key: string;
};

export enum CommentsFilterTypeEnum {
  ANY = 'any',
  ALL = 'all',
  NONE = 'none',
}
