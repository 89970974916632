import { platforms } from '../../../common/entities/entities.constants';
import { broadcastSupportedPlatforms, InstaFBPlatforms, smsSupportedPlatforms } from '../../../config/platform-action-map';
import { AmpLiteNavTabs, AmpLitePages, AmpLiteSideBarItems } from './constants';
import { NavTab, SideBarItem, TabPermissions } from './types';
import { compile } from 'path-to-regexp';
import { AmpLiteMoblinks, AmpLiteMobPages, homeTabForFbInsta, instagramInboxMessageWarning, instagramSmartRepliesMessageWarning } from '../header/mobile/SideDrawer/constants';
import { PlatformActionConfigPayload } from '../../../common/platform-action-config/platform-action-config.types';
import { checkPlatformInstaFB } from '../../broadcasts/utils';
import { ReactComponent as summary } from '../../../assets/svg/summary.svg';
import { ReactComponent as home } from '../../../assets/svg/dashboard/home.svg';
import { Account } from '../../../common/entities/entities.types';
import { getBrandName } from '../../../config/theme-config';
import { brandName as juliusBrandName } from '../../../config/julius-theme-config';
import { cloneDeep } from 'lodash';

/* eslint-disable-next-line max-lines-per-function */
export const getSidebar = (
  isOverrideNlpEnabled: boolean,
  permissions: TabPermissions,
  platformSupportedActions: string[],
  platform: string,
  additionalInfo?: any,
  isAdmin?: boolean,
  isBotworxAdmin?: boolean,
  isCliqzTypeBotConfig?: boolean,
  supportedPlatforms?: string[],
  actionPlatformConfig?: PlatformActionConfigPayload,
  currentAccount?: Account,
): SideBarItem[] => {
  let sidebarItems: any = additionalInfo?.isMobile ? [...AmpLiteMoblinks] : cloneDeep(AmpLiteSideBarItems);
  const platformsSupportingBroadcasts = getBroadcastPlatformsViaApiFlags(actionPlatformConfig, currentAccount, platform);
  const privateReplySupportedPlatforms = actionPlatformConfig && isPrivateReplySupported(actionPlatformConfig, platform);
  const { disabledTabs, disabledSubTabs } = getDisabledTabs(actionPlatformConfig, platform);
  const isJulius = getBrandName() === juliusBrandName;

  if (smsSupportedPlatforms.includes(platform) || !privateReplySupportedPlatforms) {
    sidebarItems = sidebarItems.filter((tab: SideBarItem) => !([AmpLitePages.DASHBOARD, AmpLitePages.TAGS].includes(tab.page!) || tab.title === AmpLiteNavTabs.AUTOMATION));
  }
  // removed else if as it was breaking the logic for Inbox visibility
  if (!additionalInfo.isInboxEnabled) {
    sidebarItems = sidebarItems.filter((tab: SideBarItem) => {
      if (tab.title === AmpLiteNavTabs.MODERATION) {
        tab.subTabs = tab.subTabs?.filter((subTab: NavTab) => ![AmpLitePages.INBOX].includes(subTab.page!)) || [];
      }
      return tab;
    });
    if (additionalInfo?.isMobile) {
      sidebarItems = sidebarItems.filter((tab: SideBarItem) => ![AmpLitePages.INBOX].includes(tab.page!));
    }
  }
  if (!isCliqzTypeBotConfig) {
    sidebarItems = sidebarItems.filter((tab: SideBarItem) => tab.page !== AmpLitePages.RESOURCES);
  }
  // if (isOverrideNlpEnabled || !permissions.hasKeywordGroupViewPermission) {
  //   sidebarItems = sidebarItems.filter((tab: SideBarItem) => tab.page !== AmpLitePages.KEYWORD_GROUPS);
  // }
  if (!permissions.hasTagAutomationViewPermission) {
    sidebarItems = sidebarItems
      .map((item: SideBarItem) => {
        if (item.title === AmpLiteNavTabs.SETTINGS) {
          return {
            ...item,
            subTabs: item.subTabs.filter((subTab: NavTab) => subTab.page !== AmpLitePages.TAGS),
          };
        }
        return item;
      })
      .filter((tab: SideBarItem) => tab.title !== AmpLiteNavTabs.AUTOMATION);
  }
  if (!permissions.viewACMReportsPermission) {
    sidebarItems = sidebarItems.filter((tab: SideBarItem) => tab.page !== AmpLitePages.REPORTS);
  }
  if (!permissions.viewInbox || (isJulius && !isBotworxAdmin)) {
    sidebarItems = sidebarItems.filter((tab: SideBarItem) => tab.page !== AmpLitePages.INBOX);
  }
  if (smsSupportedPlatforms.includes(platform) && !isBotworxAdmin) {
    sidebarItems = sidebarItems.filter((tab: SideBarItem) => tab.page !== AmpLitePages.REPORTS);
  }
  if (![platforms.TWILIO_SMS, platforms.FACEBOOK, platforms.INSTAGRAM, platforms.WHATSAPP, platforms.SPRINKLR_APPLE].includes(platform)) {
    sidebarItems = sidebarItems.filter((tab: SideBarItem) => ![AmpLitePages.AUDIENCE].includes(tab.page || ''));
  }
  if (!platformsSupportingBroadcasts.includes(platform) && privateReplySupportedPlatforms) {
    //privateReplySupportedPlatforms?
    sidebarItems = sidebarItems.filter((tab: SideBarItem) => ![AmpLitePages.HOME, AmpLitePages.BROADCASTS, AmpLiteMobPages.HOME].includes(tab.page || ''));
  }
  /*TODO: need to remove after data rectificatiom for FB Insta on Home*/
  if (checkPlatformInstaFB(platform)) {
    sidebarItems = sidebarItems.filter((tab: SideBarItem) => ![AmpLitePages.HOME, AmpLiteMobPages.HOME].includes(tab.page || ''));
  }
  if (!privateReplySupportedPlatforms) {
    // sidebarItems = sidebarItems.filter((tab: SideBarItem) => tab.title !== AmpLiteNavTabs.MODERATION);
    sidebarItems = sidebarItems.filter(
      (tab: SideBarItem) => !([AmpLitePages.DASHBOARD].includes(tab.page!) || [AmpLiteNavTabs.AUTOMATION, AmpLiteNavTabs.SENTIMENT_ANALYSIS].includes(tab.title!)),
    );
  }
  if (platform === platforms.WHATSAPP) {
    sidebarItems = sidebarItems.filter((tab: SideBarItem) => ![AmpLitePages.HOME].includes(tab.page || ''));
  }
  const filteredSubTabs = [...sidebarItems]
    .filter((tab: SideBarItem, index: number) => {
      /**remove after broadcast accordion implementation */
      const isPlatformInstaFB = checkPlatformInstaFB(platform);
      const pageTitle = getTabPageName(tab?.title || '');
      if (pageTitle && disabledTabs?.length && disabledTabs.includes(pageTitle)) {
        return false;
      }
      if (tab.title === 'Home' || tab.title === homeTabForFbInsta) {
        sidebarItems[index].title = isPlatformInstaFB ? homeTabForFbInsta : 'Home';
        sidebarItems[index].icon = isPlatformInstaFB ? summary : home;
      }
      if (tab.title === homeTabForFbInsta || tab.title === 'Broadcasts') {
        sidebarItems[index].additionalInfo = { ...sidebarItems[index].additionalInfo };
      }
      return true;
    })
    .map((tab: SideBarItem) => {
      const disabledSubtabs = disabledSubTabs?.[getTabPageName(tab.title) || ''] || [];
      const filteredSubTabs: any = disabledSubtabs?.length
        ? (tab.subTabs || []).filter((subtabData: any) => !disabledSubtabs.includes(subtabData?.page?.toLowerCase()))
        : tab.subTabs;
      if (tab.title === AmpLiteNavTabs.AUTOMATION) {
        return {
          ...tab,
          subTabs: filterSubTabs(filteredSubTabs, platformSupportedActions),
        };
      } else if (tab.title === AmpLiteNavTabs.MODERATION) {
        return {
          ...tab,
          subTabs:
            supportedPlatforms &&
            filterSubTabsWithPlatform(
              filteredSubTabs,
              platform,
              getPlatformsWithMentionsTab(actionPlatformConfig),
              undefined,
              undefined,
              undefined,
              privateReplySupportedPlatforms,
              undefined,
              undefined,
            ),
        };
      } else if (tab.title === AmpLiteNavTabs.SETTINGS) {
        return {
          ...tab,
          subTabs: filterSubTabsWithPlatform(
            filteredSubTabs,
            platform,
            getPlatformsWithMentionsTab(actionPlatformConfig),
            isAdmin,
            isBotworxAdmin,
            isCliqzTypeBotConfig,
            privateReplySupportedPlatforms,
            isOverrideNlpEnabled,
            permissions,
          ),
        };
      }
      return { ...tab, subTabs: filteredSubTabs };
    });

  filteredSubTabs.forEach((subTab: any) => {
    if (subTab?.title === AmpLiteNavTabs.MODERATION) {
      if (subTab?.subTabs?.length <= 1) {
        filteredSubTabs.splice(1, 0, subTab?.subTabs[0]);
        subTab.subTabs = [];
      }
    }
  });

  if (platform === platforms.INSTAGRAM && !additionalInfo?.igMessagingEnabled) {
    const inboxSubTab = filteredSubTabs.find((tab: any) => tab?.title === AmpLitePages.INBOX);
    if (inboxSubTab) {
      inboxSubTab.additionalInfo = {
        showWarning: true,
        warningToolTip: instagramInboxMessageWarning,
      };
    } else {
      const moderationTab = filteredSubTabs.find((tab: any) => tab?.title === AmpLiteNavTabs.MODERATION);
      if (moderationTab) {
        const inboxSubTab = moderationTab?.subTabs?.find((tab: any) => tab?.title === AmpLiteNavTabs.INBOX);
        if (inboxSubTab) {
          inboxSubTab.additionalInfo = {
            showWarning: true,
            warningToolTip: instagramInboxMessageWarning,
          };
        }
      }
    }
    // add similar thing in Smart Replies tab inside automation section
    const automationTab = filteredSubTabs.find((tab: any) => tab?.title === AmpLiteNavTabs.AUTOMATION);
    if (automationTab) {
      const smartRepliesSubTab = automationTab?.subTabs?.find((tab: any) => tab?.title === AmpLiteNavTabs.SMART_REPLIES);
      if (smartRepliesSubTab) {
        smartRepliesSubTab.additionalInfo = {
          showWarning: true,
          warningToolTip: instagramSmartRepliesMessageWarning,
        };
      }
    }
  }

  return filteredSubTabs;
};

/**
 *  Automate tab support actions like below,
 * Moderation - 'hide', 'block', 'delete'
 * Brand Lift - like.
 * actionsInSmartReplies - public reply.
 * If none of actions supported by platform in that subtab then subtab should be hidden.
 * @param subTabs
 * @param platformSupportedActions
 */
const filterSubTabs = (subTabs: any, platformSupportedActions: string[]) => {
  if (!subTabs || !subTabs.length || !platformSupportedActions || !platformSupportedActions.length) {
    return subTabs;
  }
  const filterSubTabs = subTabs.filter((tab: any) => {
    if (tab && tab.actions) {
      return tab.actions.some((a: string) => platformSupportedActions.includes(a));
    }
    return true;
  });
  return filterSubTabs;
};

/*eslint-disable max-lines-per-function*/
const filterSubTabsWithPlatform = (
  subtabs: NavTab[],
  platform: string,
  _platformsWithMentionsTab: string[],
  isAdmin?: boolean,
  isBotworxAdmin?: boolean,
  isCliqzTypeBotConfig?: boolean,
  privateReplySupportedPlatforms?: boolean,
  isOverrideNlpEnabled?: boolean,
  permissions?: TabPermissions,
) => {
  const filteredSubTabs = subtabs.filter((subtab: any) => {
    if (subtab.page === AmpLitePages.COMMENTS) {
      return privateReplySupportedPlatforms;
    } else if ([AmpLitePages.BOT_SETTINGS].includes(subtab.page)) {
      return (
        ![platforms.TWITTER].includes(platform) &&
        isAdmin &&
        (isCliqzTypeBotConfig || (subtab.page === AmpLiteMobPages.ACCOUNT_SETTINGS && platform === platforms.TWILIO_SMS) || (checkPlatformInstaFB(platform) && isBotworxAdmin))
      );
    } else if ([AmpLitePages.SEGMENTS].includes(subtab.page)) {
      return permissions?.viewSegmentsPermission;
    } else if ([AmpLitePages.INGEST_LIST].includes(subtab.page)) {
      return [platforms.TWILIO_SMS, platforms.WHATSAPP].includes(platform) && permissions?.viewIngestListPermission;
    } else if ([AmpLitePages.TEMPLATES].includes(subtab.page)) {
      if (!permissions?.hasTemplateViewPermission) {
        return false;
      }
    } else if ([AmpLitePages.KEYWORD_GROUPS].includes(subtab.page)) {
      return !isCliqzTypeBotConfig && !isOverrideNlpEnabled && permissions?.hasKeywordGroupViewPermission;
    } else if (subtab.page === AmpLitePages.UTILITY) {
      return isBotworxAdmin ? true : false;
    } else if (subtab.page === AmpLitePages.TAGS) {
      return privateReplySupportedPlatforms;
    }
    return true;
  });
  return filteredSubTabs;
};

const getConvertFunction = (path: string) => {
  return compile(path, { encode: encodeURIComponent });
};
export const convertToActualPath = (path: string, accountId: string, platformBotId: string) => {
  if (!accountId || accountId?.trim() === '') {
    return path;
  }
  const convertFunc = getConvertFunction(path);
  return convertFunc({ accountId, platformBotId });
};
export const subTabsWithActualPaths = (subTabs: NavTab[], accountId: string, platformBotId: string) => {
  return subTabs.map((subTab: NavTab) => {
    return { ...subTab, route: convertToActualPath(subTab.route, accountId, platformBotId) };
  });
};

export const isPrivateReplySupported = (actionPlatformConfig: PlatformActionConfigPayload, platform: string) => {
  return actionPlatformConfig && platform && actionPlatformConfig[platform]?.privateReplySupported ? actionPlatformConfig[platform]?.privateReplySupported : false;
};

export const getBroadcastPlatformsViaApiFlags = (platformActionConfig?: PlatformActionConfigPayload, currentAccount?: Account, currentPlatform?: string) => {
  const whatsAppPlatformProviders = platformActionConfig?.[platforms.WHATSAPP]?.providers || [];
  return currentPlatform && checkPlatformInstaFB(currentPlatform) && currentAccount?.config?.uiConfig?.[currentPlatform]?.showNotificationBroadcastOnCM
    ? [...broadcastSupportedPlatforms, ...InstaFBPlatforms, ...whatsAppPlatformProviders]
    : [...broadcastSupportedPlatforms, ...whatsAppPlatformProviders];
};

/**remove after broadcast accordion implementation */
export const renameHomeTabForInstFB = (tabName: string = '', platform?: any) =>
  checkPlatformInstaFB(platform) && tabName === 'Home' ? homeTabForFbInsta : tabName.replace("<span class='search-heighlight'>", '').replace('</span>', '');

export const getDisabledTabs = (systemConfig?: PlatformActionConfigPayload, platform?: string) => {
  const disabledTabs: string[] = [];
  const disabledSubTabs: any = {};
  const disabledTabsForPlatform: any = platform && systemConfig?.[platform]?.disabledTabs;

  disabledTabsForPlatform &&
    Object.keys(disabledTabsForPlatform).forEach((tabName: any) => {
      const pageName = tabName?.toLowerCase() || '';
      if (disabledTabsForPlatform[tabName]?.self) disabledTabs.push(pageName);
      else if (disabledTabsForPlatform[tabName]?.subtabs?.length) {
        disabledSubTabs[pageName] = disabledTabsForPlatform[tabName].subtabs?.map((subTabName?: string) => subTabName?.toLowerCase() || '');
      }
    });

  return { disabledTabs, disabledSubTabs };
};

export const getTabPageName = (nameString?: string) => {
  return nameString?.replace(/\s/g, '')?.toLowerCase() || '';
};

export const getPlatformsWithMentionsTab = (actionPlatformConfig?: PlatformActionConfigPayload) => {
  return actionPlatformConfig ? Object.keys(actionPlatformConfig).filter((platform: string) => (actionPlatformConfig[platform].mention ? true : false)) : [];
};
