import React from 'react';
import styles from '../InvitationAccepted.module.scss';
import { useTillerInviteContext } from '../hooks';
import SocialLoginButton from './SocialLoginButton';
import { ReactComponent as ErrorIcon } from '../../../assets/svg/Error.svg';
import { ReactComponent as CheckIcon } from '../../../assets/svg/check-circle.svg';
import Loader from 'react-loader-spinner';
import classNames from 'classnames';

const ButtonContainer = () => {
  const { isInValidInvite, isValidatingInvite, isLinkedSuccessfully, igRedirectData, isAlreadyLinked } = useTillerInviteContext();
  return (
    <div className={classNames(styles.buttonContainer, igRedirectData?.errorMessage ? styles.hasIGError : undefined)}>
      {isValidatingInvite ? (
        <Loader type='ThreeDots' color={'#ff3960'} height={40} width={40} />
      ) : isInValidInvite ? (
        <div className={styles.invalidInvite}>
          <ErrorIcon height={30} />
          <span> {igRedirectData ? igRedirectData.errorMessage : 'Invalid invite!'}</span>
        </div>
      ) : isLinkedSuccessfully || isAlreadyLinked ? (
        <div className={styles.inviteAccepted}>
          <CheckIcon height={30} color={'#79c32f'} />
          <span> {`Invitation ${isAlreadyLinked ? 'already ' : ''}accepted !`}</span>
        </div>
      ) : (
        <SocialLoginButton />
      )}
      <div className={styles.privacyPolicy}>
        By logging in, I agree that I have read <br /> and accepted the <strong>Terms of Use</strong> and <strong>Privacy Policy</strong>.
      </div>
    </div>
  );
};

export default ButtonContainer;
