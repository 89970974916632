import { CampaignType, AnalyticsResponseType, AnalyticsResponseObjType, AnalyticsObjUiTYpe, ConditionType, IfStatementType, GroupConditionType } from '../types';
import { v4 as uuid } from 'uuid';
import { PrivateReplyActionType } from '../constants';
import { tagMacro } from '../config';
import { getCreatedForObject } from '../create-gc/utils';

export function transformCampaignAPItoUI(campaign: CampaignType, analyticsForCampaign?: AnalyticsResponseType, mentionAnalyticsForCampaign?: AnalyticsResponseType) {
  const transformedCampaign = {
    ...campaign,
    actions: transformGcResponseActionAPIToUI(campaign),
    ...(campaign.commentFilter &&
      campaign.commentFilter.filterType === 'expression' &&
      campaign.commentFilter.filterValue && { targetPercentage: getValueFromExpression(campaign.commentFilter.filterValue) }),
    ...((analyticsForCampaign || mentionAnalyticsForCampaign) && { analytics: transformAnalyticsForUI(analyticsForCampaign, mentionAnalyticsForCampaign) }),
    ...(campaign.conditions && { selectedTag: convertConditionsToTag(campaign.conditions) }),
    hasAdvancedConditions: checkIfAdvancedConditions(campaign.conditions),
    createFor: getCreatedForObject(campaign.commentType, undefined, campaign.targetPostMentions),
  };
  delete transformedCampaign.commentFilter;

  return transformedCampaign;
}

const getValueFromExpression = (expression: string = '') => {
  let x;
  const regex = /\(Math.random\(\)\*100\s*<=\s*\d+(?=\s*\))/;

  if (regex.test(expression)) {
    const matchTo = /\d+(?=\s*\)$)/;
    const valARR = expression.match(matchTo);

    x = Number(valARR ? valARR[0] : undefined);
  }
  return x;
};

export function transformGcResponseActionAPIToUI(transformedCampaign: CampaignType) {
  const actions = transformedCampaign.actions || [];
  return actions.map((a: any) => {
    return {
      ...a,
      id: uuid(),
      type: a.type === PrivateReplyActionType.TEXT ? PrivateReplyActionType.PRIVATE : a.type,
    };
  });
}

const transformAnalyticsForUI = (analytics?: AnalyticsResponseType, mentionAnalyticsForCampaign?: AnalyticsResponseType) => {
  const analyticsTransformed: AnalyticsObjUiTYpe = {};
  analytics &&
    analytics.forEach((analyticsObj: AnalyticsResponseObjType) => {
      analyticsTransformed[analyticsObj.key] = { ...analyticsTransformed[analyticsObj.key], comments: analyticsObj };
    });
  mentionAnalyticsForCampaign &&
    mentionAnalyticsForCampaign.forEach((analyticsObj: AnalyticsResponseObjType) => {
      analyticsTransformed[analyticsObj.key] = { ...analyticsTransformed[analyticsObj.key], mentions: analyticsObj };
    });
  return analyticsTransformed;
};

export const convertConditionsToTag = (conditions: ConditionType) => {
  let tagName;
  if (conditions && conditions.childConditions && conditions.childConditions[0] && conditions.childConditions[0].ifStatements) {
    const ifStatementWithTag = conditions.childConditions[0].ifStatements.find((ifStatement: IfStatementType) => {
      return ifStatement.variable.macro === tagMacro;
    });
    tagName = ifStatementWithTag?.value;
  }
  return tagName;
};

export const checkIfAdvancedConditions = (conditions: ConditionType) => {
  if (conditions && conditions.childConditions) {
    if (conditions.childConditions.length > 2) {
      return true;
    }
    return conditions.childConditions.some((childCondition: GroupConditionType) => {
      return checkIfAdvancedChildCondition(childCondition);
    });
  }

  return false;
};

const checkIfAdvancedChildCondition = (childCondition: GroupConditionType) => {
  if (childCondition.ifStatements && childCondition.ifStatements.length > 1) {
    return true;
  } else if (childCondition.ifStatements && childCondition.ifStatements.length === 1) {
    return ![tagMacro].includes(childCondition.ifStatements[0].variable.macro);
  }
  return false;
};

// export const newResponseToOldresponseAdaptor = (newResponse: newResponse) => {
//   const oldresponse: { [key: string]: AnalyticsResponseType } = {};

//   newResponse.forEach(({ key, subGroups }: SbGrps) => {
//     const paidOrOrgainic = key;
//     subGroups.forEach((element: SbGrp) => {
//       const dataPerGC = oldresponse[element.key] || [];
//       oldresponse[element.key] = [...dataPerGC, { ...element, key: paidOrOrgainic }];
//     });
//   });
//   debugger;
//   return oldresponse;
// };
export const newResponseToOldresponseAdaptor = (newResponse: newResponse) => {
  const oldresponse: { [key: string]: AnalyticsResponseType } = {};

  newResponse.forEach(({ key, subGroups }: SbGrps) => {
    const campaignId = key;
    oldresponse[campaignId] = subGroups;
  });

  return oldresponse;
};

export type newResponse = SbGrps[];
export type SbGrps = {
  subGroups: SbGrp[];
  key: number;
};

type SbGrp = {
  key: number;
} & AnalyticsResponseObjType;
