/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
// import moment from 'moment';
import moment from 'moment-timezone';
import { setFetchingDonationAmount, setFetchingMessageUsage, setNotificationLabels, storeAudiencedata, storeDonationAmount, storeMessageUsage } from '.';
import { get, post, put, patch } from '../../../common/api-calls/api-client';
import { apiServerUrl, customAnalyticsAPI } from '../../../common/api-calls/config';
import { addTestUsersToBot, storeBroadcast, updateModule } from '../../../common/entities/actions';
import { moduleTypeApi } from '../../../common/entities/entities.types';

import { getBroadcastStartDateTransformedToApi, showErrorModal, validateDateRangeNotMoreThan3YearsAgo } from '../../../common/utils';
import { getTimeZone } from '../../../common/utils/time-zones';
import { Entity } from '../../../constants';
import { AsyncThunkDispatch, RootState, Thunk } from '../../../types';
import { showMessage } from '../../common/toast-notification';
import { broadcastScheduleConst } from '../constants';
import { broadcastsToUI, messageUsageToUI, transformNotificationLabelApiToUi } from '../transformer';
import { CreateBroadcastApiRequest, UpdateBroadcastApiRequest, BroadcastTypeAPI, BroadcastTypeUI, PreviewUsage, ReferralData } from '../types';

// import transformations from '../transformer';
export const fetchSmsUsageForBot = (botId: string, startDate: string, EndDate: string, mmsFactor?: number): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    dispatch(setFetchingMessageUsage(true));
    const { auth } = getState();
    const authToken = auth.authToken;
    try {
      const previousStartDate = moment(startDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const previousEndDate = moment(startDate).subtract(1, 'days').format('YYYY-MM-DD');

      const [currentMonthResponse, prevMonthResponse] = await Promise.all([
        get(`reporting/messagingUsage?botId=${botId}&startDate=${startDate}&endDate=${EndDate}`, authToken as string, true),
        get(`reporting/messagingUsage?botId=${botId}&startDate=${previousStartDate}&endDate=${previousEndDate}`, authToken as string, true),
      ]);

      if (currentMonthResponse?.data && prevMonthResponse?.data) {
        const messageUsage = messageUsageToUI(currentMonthResponse?.data, mmsFactor);
        const prevMonthMessageUsage = messageUsageToUI(prevMonthResponse?.data, mmsFactor);

        const prevMonthTotals = prevMonthMessageUsage.usageArray?.length
          ? prevMonthMessageUsage.usageArray.reduce((acc: any, item: any) => {
              acc[item.type] = item.total;
              return acc;
            }, {})
          : undefined;

        // Traverse current month usageArray and assign totalPreviousMonth from the map
        prevMonthTotals &&
          messageUsage.usageArray.forEach((item: any) => {
            item.totalPreviousMonth = prevMonthTotals[item.type] || 0;
          });

        dispatch(storeMessageUsage(messageUsage));
        dispatch(setFetchingMessageUsage(false));
      }
    } catch (error: any) {
      dispatch(setFetchingMessageUsage(false));
      showErrorModal(error, dispatch);
    }
  };
};

export const fetchPreviewDetails = (botId: string, url: string): Thunk<PreviewUsage[] | null> => {
  return async (_dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<PreviewUsage[] | null> => {
    const { auth } = getState();
    const authToken = auth.authToken;
    let response = null;
    try {
      response = await get(`/webupdateMessages/scrape?botId=${botId}&scrapUrl=${url}`, authToken as string, true);
      if (response?.data) {
        return response.data;
      }
      return null;
    } catch {
      return null;
    }
  };
};

export const getBroadcastById = async (broadcastId: string, authToken: string, throwErrorIfAny: boolean = false): Promise<BroadcastTypeAPI | null> => {
  try {
    const newBroadcastResponse = await get(`broadcasts/${broadcastId}`, authToken as string);
    return newBroadcastResponse?.status === 200 && newBroadcastResponse?.data ? newBroadcastResponse.data : null;
  } catch (error: any) {
    //eslint-disable-next-line no-console
    console.log(error);
    if (throwErrorIfAny) {
      throw error;
    }
    return null;
  }
};

export const createBroadcast = (payload: CreateBroadcastApiRequest): Thunk<BroadcastTypeAPI | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<BroadcastTypeAPI | null> => {
    let response: { data: BroadcastTypeAPI; status: number } | null = null;
    const {
      auth: { authToken },
    } = getState();
    try {
      response = await post(`/broadcasts`, payload, authToken as string);
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
    if (response && response.status === 200) {
      return response.data;
    }
    return null;
  };
};

export const updateBroadcast = (
  payload: UpdateBroadcastApiRequest | { name: string; id: string },
  enableInputNodeAccess?: boolean,
  enableDefaultUSTargeting?: boolean,
): Thunk<BroadcastTypeUI | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<BroadcastTypeUI | null> => {
    const editUrl = `broadcasts`;
    let response: { data: BroadcastTypeAPI; status: number } | null = null;
    const {
      auth: { authToken },
      entities,
    } = getState();

    try {
      /*re-examine logic for 3 calls for a simple update*/
      let broadcast = await getBroadcastById(payload.id, authToken as string, true); //to get latest broadcastInfo
      if (broadcast) {
        broadcast.name = payload.name;
        broadcast = {
          ...broadcast,
          ...payload,
          lastUpdatedDate: broadcast.lastUpdatedDate,
        };
        if (!enableInputNodeAccess) {
          delete broadcast.setVariables;
          delete broadcast.captureUserInput;
        }
        try {
          response = await put(editUrl, broadcast, authToken as string); //to update broadcast
          if (response?.status === 204) {
            broadcast = (await getBroadcastById(payload.id, authToken as string)) || broadcast; //fetch broadcast again to update testBroadcastInfo & get latest data
            // let broadcasttoUI = entities.broadcasts[payload.id];
            // const response = removeURLTracking(broadcast.responses || []);
            // broadcasttoUI = { ...broadcasttoUI, broadcastName: broadcast.name, actions: response, lastUpdatedDate: broadcast.lastUpdatedDate };
            const broadcastToUI = broadcastsToUI([broadcast], entities?.account?.timezone || '', enableDefaultUSTargeting);
            return Object.values(broadcastToUI)[0];
          }
        } catch (error: any) {
          showErrorModal(error, dispatch);
          return null;
        }
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
      return null;
    }
    return null;
  };
};

export const sendTestBroadcastMessages = (botId: string, broadcastId: string, broadcastTestUsers: any[], broadcastTestUserTargetingId: string | null, onDone?: any): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const url = `broadcasts/test`;
    let response: any;
    const state = getState();
    const authToken = state.auth?.authToken;

    const userObj: any = {
      botId,
      broadcastId,
      broadcastTestUsers,
    };

    if (broadcastTestUserTargetingId) {
      userObj.broadcastTestUserTargetingId = broadcastTestUserTargetingId;
      delete userObj.broadcastTestUsers;
    }

    try {
      response = await post(url, userObj, authToken as string);
      if (response && response.data) {
        let testUsers: any = {};
        if ((response.data.success && response.data.success.length) || (response.data.failure && response.data.failure.length)) {
          testUsers = response.data;
        } else {
          testUsers.success = [...broadcastTestUsers];
          testUsers.failure = [];
          const fetchedBroadcastResponse = await get(`broadcasts/${userObj.broadcastId}`, authToken as string);
          if (fetchedBroadcastResponse?.data) {
            const timezone = getTimeZone(state.entities?.account?.timezone);
            const broadcast = broadcastsToUI([fetchedBroadcastResponse?.data], timezone);
            const bcUI = Object.values(broadcast)[0];
            dispatch(storeBroadcast(bcUI));
          }
        }
        dispatch(addTestUsersToBot({ botId: userObj.botId, testUsers }));
        onDone && onDone();
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const pauseResumeBroadcast = (
  broadcastId: string,
  action: string,
  completed: boolean,
  timezone: string,
  done?: (value?: string) => void,
  payload?: UpdateBroadcastApiRequest,
): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const editUrl = `broadcasts`;
    const {
      auth: { authToken },
    } = getState();

    try {
      let broadcast: any = await getBroadcastById(broadcastId, authToken as string, true);
      if (broadcast) {
        if (payload) {
          broadcast = {
            ...broadcast,
            ...payload,
            type: broadcastScheduleConst.LATER.value,
            lastUpdatedDate: broadcast.lastUpdatedDate,
          };
        }
        if (completed) {
          broadcast.completed = true;
        }
        if (action === 'resume') {
          delete broadcast.paused;
          delete broadcast.draft;
          const updatedStartDateObj = getBroadcastStartDateTransformedToApi(broadcast, timezone);
          broadcast = { ...broadcast, ...updatedStartDateObj };
        } else {
          broadcast.paused = true;
        }
        // In case of broadcast date/time conflict
        // const broadcastStartDate = moment(broadcast.date + ' ' + broadcast.time);
        // const accountOffset = moment.tz(timezone).utcOffset();
        // const broadcastDate = moment(broadcastStartDate).utcOffset(accountOffset, true);
        // const currentDate = moment.tz(timezone);
        // if (currentDate.isAfter(broadcastDate)) console.log('asdaskdh jashkjahsjhkhak jkjdashd');
        // const startDate = broadcastDate > currentDate ? broadcast.startDate : moment.tz(timezone).add(5, 'minutes');
        // console.log(broadcastDate, currentDate, startDate);
        try {
          const putResp = await put(editUrl, broadcast, authToken as string);
          if (putResp?.status === 204) {
            broadcast = (await getBroadcastById(broadcastId, authToken as string)) || broadcast; //fetch broadcast again to update testBroadcastInfo & get latest data
            const toUI = broadcastsToUI([broadcast], timezone);
            const bcUI = Object.values(toUI)[0];
            dispatch(storeBroadcast(bcUI));
            // updateBroadcast(broadcast, appendTextValue);
            action === 'resume' && showMessage(`Broadcast ${bcUI.scheduleType === broadcastScheduleConst.LATER.value ? 'Scheduled' : 'sent'}`);
            done?.(putResp?.headers?.date);
          }
        } catch (error: any) {
          showErrorModal(error, dispatch);
        }
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const fetchAudienceForBot = (botId: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const { auth } = getState();
    const authToken = auth.authToken;
    let response;

    try {
      response = await get(`bots/${botId}/audience`, authToken as string, false);
      if (response?.data) {
        const messageUsage = response?.data;
        dispatch(storeAudiencedata(messageUsage));
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const fetchModuleReferences = (moduleId: string, versionId: string, callback?: (response: any) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const { auth } = getState();
    const authToken = auth.authToken;
    let response;

    try {
      response = await get(`modules/${moduleId}/moduleReferences?versionId=${versionId}`, authToken as string, false);
      if (response?.data) {
        callback && callback(response?.data);
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const createEncryptedRefId = (payload: ReferralData, callback?: (res: string) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const url = `referrals/createEncryptedRefId`;
    let response: any;
    const {
      auth: { authToken },
    } = getState();

    try {
      response = await post(url, payload, authToken as string, false, 'text');
      if (response && response.data) {
        callback && callback(response.data);
      } else {
        callback && callback('');
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const fetchTestLink = (versionId: string, callback?: (res: string) => void): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const {
      auth: { authToken },
    } = getState();
    try {
      fetch(`${apiServerUrl}versions/${versionId}/testLink`, {
        method: 'GET',
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'x-access-token': authToken as string,
        },
      }).then((res: any) => {
        res.text().then((url: string) => {
          if (res && res.status === 200) {
            url && callback && callback(url);
          } else {
            callback && callback('');
          }
        });
      });
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

// export const fetchTestLink = (versionId: string): Thunk => {
//   return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
//     const {
//       auth: { authToken },
//     } = getState();
//     try {
//       fetch(`${apiServerUrl}versions/${versionId}/testLink`, {
//         method: 'GET',
//         headers: {
//           'x-access-token': authToken as string,
//         },
//       }).then((res: any) => {
//         res.text().then((url: string) => {
//           if (res && res.status === 200) {
//             url && dispatch(setTestLink(url));
//           }
//         });
//       });
//     } catch (error: any) {
//       showErrorModal(error, dispatch);
//     }
//   };
// };

export const fetchEntityAuditLogs = (accountId: string, botId: string, entityId: string, callback?: (logs: any[]) => void, entityType: string = Entity.BROADCAST) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const authToken = state.auth.authToken as string;
    let response;
    try {
      //currently working only for botworx admins
      response = await get(
        `auditLogs?entityType=${entityType}&botId=${botId}&accountId=${accountId}&requestType=update&entityId=${entityId}&fetchForAllUsers=true`,
        authToken as string,
        false,
      );
      if (response?.data) {
        callback?.(response?.data);
      }
    } catch (error: any) {
      false && showErrorModal(error, dispatch);
    }
  };
};

export const updateAModule = (module: moduleTypeApi, callback?: (isSuccess: boolean) => void) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const authToken = state.auth.authToken as string;
    let response;
    try {
      response = await patch(`modules?versionId=${module.versionId}`, module, authToken);
      if (response.status === 204) {
        const newModuleResponse = await get(`modules/${module.id}?versionId=${module.versionId}`, authToken);
        dispatch(updateModule({ versionId: module.versionId || '', module: newModuleResponse.data || module }));
        callback?.(true);
        return;
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
    callback?.(false);
  };
};

export const getMessageVariant = (botId: string, text: string, prompt: string, callback: (data: string) => void) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const authToken = state.auth.authToken as string;
    let response;
    try {
      response = await post('ai/messageVariants', { botId, text, prompt }, authToken as string);
      if (response?.data) {
        callback(response?.data[0] || '');
      }
    } catch (error) {
      showErrorModal(error, dispatch);
    }
  };
};

export const fetchAModule = (moduleId: string, versionId: string, doNotShowAPILoader: boolean) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const state = getState();
    const authToken = state.auth.authToken as string;
    try {
      const moduleResponse = await get(`modules/${moduleId}?versionId=${versionId}`, authToken, doNotShowAPILoader);

      dispatch(updateModule({ versionId, module: moduleResponse.data }));
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const fetchNotificationLabelsForBotVersion = (botId: string, versionId: string) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const authToken = state.auth.authToken as string;
    try {
      const notificationLabelsResponse = await get(`notificationLabels?botId=${botId}&flowId=${versionId}`, authToken);
      const notificationLabels = transformNotificationLabelApiToUi(notificationLabelsResponse?.data);
      dispatch(setNotificationLabels(notificationLabels));
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};

export const fetchDonationAmount = (botId: string, accountId: string, startDate: string, endDate: string): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    dispatch(setFetchingDonationAmount(true));
    const state = getState();
    const authToken = state.auth.authToken as string;
    const newDates = validateDateRangeNotMoreThan3YearsAgo(startDate, endDate);
    startDate = newDates.startDate;
    endDate = newDates.endDate;
    const payload = {
      filters: [{ field: 'isAmplifyRefcode', eq: ['true'] }],
      startDate: parseInt(startDate.replace(/-/g, '')),
      endDate: parseInt(endDate.replace(/-/g, '')),
      accountId,
      botId,
      aggregationFields: ['donationAmount', 'donationCount', 'refundAmount', 'refundCount', 'cancelationCount'],
      indexName: 'donations',
      groupByField: 'date',
      groupByBucketLimit: 28,
    };

    try {
      const response = await post(customAnalyticsAPI, payload, authToken);

      if (response?.data) {
        const totalDonationAmount = response.data.reduce((sum: any, item: any) => sum + (item.donationAmount || 0), 0);
        dispatch(storeDonationAmount(totalDonationAmount));
      }
    } catch (error) {
      showErrorModal(error, dispatch);
    } finally {
      dispatch(setFetchingDonationAmount(false));
    }
  };
};
