import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';
import { BotConfig, BotProfile, BotPromptSettings } from '../../../common/entities/entities.types';
import { storeBotConfig, storeBotMessageDefaultValue, storeBotMessageSettings, storeBotProfile, storeBotSettings, storeSMSText } from '../actions';
import { DynamicFieldUIMapped } from '../settings.types';

export const initialState: {
  uiPlatformConfig: DynamicFieldUIMapped | null;
  profileDetails: BotProfile;
  smsText: string | null;
  botConfig?: BotConfig;
  botSettings?: BotPromptSettings;
} = { uiPlatformConfig: null, profileDetails: {}, smsText: null };

const settingsReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeBotMessageSettings, (state: typeof initialState, action: ReturnType<typeof storeBotMessageSettings>) => {
      state.uiPlatformConfig = action.payload;
    })
    .addCase(storeBotMessageDefaultValue, (state: typeof initialState, action: ReturnType<typeof storeBotMessageDefaultValue>) => {
      Object.entries(action.payload).forEach((setting: [string, string]) => {
        if (state.uiPlatformConfig && state.uiPlatformConfig[setting[0]]) {
          state.uiPlatformConfig[setting[0]].defaultValue = setting[1];
        }
      });
    })
    .addCase(storeBotProfile, (state: typeof initialState, action: ReturnType<typeof storeBotProfile>) => {
      state.profileDetails = action.payload;
    })
    .addCase(storeSMSText, (state: typeof initialState, action: ReturnType<typeof storeSMSText>) => {
      state.smsText = action.payload;
    })
    .addCase(storeBotConfig, (state: typeof initialState, action: ReturnType<typeof storeBotConfig>) => {
      state.botConfig = action.payload;
    })
    .addCase(storeBotSettings, (state: typeof initialState, action: ReturnType<typeof storeBotSettings>) => {
      state.botSettings = action.payload;
    });
});

export default settingsReducer;
