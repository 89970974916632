import React, { ReactElement } from 'react';
import { Select, SelectProps } from 'antd';
import { SelectOption as SelectOptionType } from '../../../types';
import SelectDropdownArrow from '../select-dropdown-arrow';
import { getHeaderFontsAndIconsColor } from '../../../config/theme-config';

type SelectDropdownProps = {
  options: SelectOptionType[];
  handleSelectDropdownChange?: (newSelection: string) => void;
  onDeselect?: () => void;
  selectedValue?: string | string[] | any;
  placeholder?: string;
  [key: string]: unknown;
  mode?: string;
  elementRef?: any;
} & SelectProps<any>;

const SelectDropdown = (props: SelectDropdownProps): ReactElement => {
  const { options, handleSelectDropdownChange, selectedValue, placeholder = 'Please Select', mode, onDeselect, elementRef, ...rest } = props;

  return (
    <Select
      mode={mode}
      value={selectedValue}
      placeholder={placeholder}
      onDeselect={onDeselect}
      onChange={handleSelectDropdownChange}
      suffixIcon={<SelectDropdownArrow fill={getHeaderFontsAndIconsColor()} />}
      ref={elementRef}
      {...rest}
    >
      {options.map(
        (option: SelectOptionType): ReactElement => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ),
      )}
    </Select>
  );
};

export default SelectDropdown;
