import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { automationSubTabs } from '../constants';
import { setActiveTabPane, storeBasePath, storeCampaigns } from '../actions';
import { AutomationState } from '../types';

export const initialState: AutomationState = {
  campaignsSegregated: {
    [automationSubTabs.BRAND_LIFT]: {
      custom: [],
      recommended: [],
    },
    [automationSubTabs.COMMENT_MODERATION]: {
      custom: [],
      recommended: [],
    },
    [automationSubTabs.SMART_REPLIES]: {
      custom: [],
      recommended: [],
    },
  },
  activeTabPane: 'Recommended',
  privateRepliesLoaded: false,
  campaigns: {},
};

const commentModerationReducer = createReducer(initialState, (builder: ActionReducerMapBuilder<typeof initialState>) => {
  builder
    .addCase(storeCampaigns, (state: typeof initialState, action: ReturnType<typeof storeCampaigns>) => {
      if (action.payload.campaignSegregated) {
        state.campaignsSegregated = action.payload.campaignSegregated;
        state.privateRepliesLoaded = true;
      }
      if (action.payload.campaigns) {
        state.campaigns = action.payload.campaigns;
      }
    })
    .addCase(setActiveTabPane, (state: typeof initialState, action: ReturnType<typeof setActiveTabPane>) => {
      state.activeTabPane = action.payload;
    })
    .addCase(storeBasePath, (state: typeof initialState, action: ReturnType<typeof storeBasePath>) => {
      state.basePath = action.payload;
    });
});

export default commentModerationReducer;
