import { apiServerUrl } from 'src/common/api-calls/config';

export const getApiEndPointForInstagramDeployment = (accountId: string, botId: string, authToken: string, finalCallbackUrl?: string) => {
  let params = `accountId=${accountId}&access_token=${authToken}`;
  if (botId) {
    params += `&botId=${botId}`;
  }
  params += `&finalCallbackUrl=${finalCallbackUrl ? finalCallbackUrl : document.location.origin + document.location.pathname}`;
  const url = `${apiServerUrl}platformBots/deployInstagramBot?${params}`;
  return url;
};
