import { countryNamesAndValuesForBroadcastTargeting } from 'src/common/constants';
import { BotConfigType } from '../../common/entities/entities.types';

export type SettingsConstantType = {
  STORE_BOT_MESSAGE_SETTINGS: string;
  STORE_BOT_PROFILE_DETAILS: string;
  STORE_BOT_CONFIG_TYPE: string;
  STORE_BOT_MESSAGE_DEFAULT_VALUE: string;
  STORE_SMS_TEXT: string;
  BLACKLIST_ERRORS: string;
  STORE_CROP_DIMENTIONS: string;
  STORE_BOT_CONFIG: string;
  STORE_BOT_SETTINGS: string;
};
export const constants: SettingsConstantType = {
  STORE_BOT_MESSAGE_SETTINGS: 'STORE_BOT_MESSAGE_SETTINGS',
  STORE_BOT_PROFILE_DETAILS: 'STORE_BOT_PROFILE_DETAILS',
  STORE_BOT_CONFIG_TYPE: 'STORE_BOT_CONFIG_TYPE',
  STORE_BOT_MESSAGE_DEFAULT_VALUE: 'STORE_BOT_MESSAGE_DEFAULT_VALUE',
  STORE_SMS_TEXT: 'STORE_SMS_TEXT',
  BLACKLIST_ERRORS: 'BLACKLIST_ERRORS',
  STORE_CROP_DIMENTIONS: 'STORE_CROP_DIMENTIONS',
  STORE_BOT_CONFIG: 'STORE_BOT_CONFIG',
  STORE_BOT_SETTINGS: 'STORE_BOT_SETTINGS',
};

export const settingsTab = {
  accountInfo: 'accountInfo',
  signUpMessages: 'signUpMessages',
  admin: 'admin',
  botConfig: 'botConfig',
  prompts: 'prompts',
  accountConfig: 'accountConfig',
};

//eslint-disable-next-line @typescript-eslint/naming-convention
export const MAX_SIZE_FOR_IMAGE_UPLOAD = 1; //in MB

export const imageValidationError = {
  MAX_SIZE: `Image size should be less then ${MAX_SIZE_FOR_IMAGE_UPLOAD} MB.`,
};

export const botConfigTypeCliqz: BotConfigType = 'cliqz';

export const cliqzLogoUrl = 'https://botworx.s3.amazonaws.com/images/5cde4df78dbd010516998662/cliqz-logo_(1)_1645724164.png';

//eslint-disable-next-line @typescript-eslint/naming-convention
export const CLIQZ_SIGNUP_POSTBACK = 'CLIQZ_SIGNUP_POSTBACK';

type BotProfileMacros = {
  primaryPhoneNumber: 'PRIMARY_PHONE_NUMBER';
  profilePic: 'BOT_PROFILE_PIC';
  name: 'BRAND';
  [key: string]: string;
};

export const botProfileMacros: BotProfileMacros = {
  primaryPhoneNumber: 'PRIMARY_PHONE_NUMBER',
  profilePic: 'BOT_PROFILE_PIC',
  name: 'BRAND',
  popupId: 'POPUPID',
  firstName: 'FIRST_NAME',
  lastName: 'LAST_NAME',
};

export const fakeDemoGraphicUrl = 'https://cliqzhq.afy.ai/qy4ks';

export const blackListErrors = 'blackListErrors';

export const countryOptionsForBroadcasts = [
  {
    label: countryNamesAndValuesForBroadcastTargeting.US.name,
    value: countryNamesAndValuesForBroadcastTargeting.US.value,
  },
  {
    label: countryNamesAndValuesForBroadcastTargeting.ALL.name,
    value: countryNamesAndValuesForBroadcastTargeting.ALL.value,
  },
];
