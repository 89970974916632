import { post } from 'src/common/api-calls/api-client';
import { showErrorModal } from 'src/common/utils';
import { showMessage } from 'src/pages/common/toast-notification';
import { AsyncThunkDispatch, RootState, Thunk } from 'src/types';

export type OptOutRequestType = {
  username?: string;
  email?: string;
  deleteVideos?: boolean;
};

export const optOutStt = (payload: OptOutRequestType): Thunk<OptOutRequestType | null> => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<OptOutRequestType | null> => {
    const { auth } = getState();
    const authToken = auth.authToken;
    const url = `tiktok/optOut`;
    // const payload = {
    //   username,
    //   ...(deleteVideos ? { deleteVideos: true } : {}),
    // };

    let response: any = null;
    try {
      response = await post(url, payload, authToken as string);
      showMessage(`Opt Out Successful for the user ${payload.email || payload.username}`, 'success');
      return response.data;
    } catch (error: any) {
      debugger;
      showErrorModal(error, dispatch, undefined, error?.response?.data?.message || 'Failed to Opt Out.', true);
    }
    return null;
  };
};
