import React, { ReactElement, ReactNode } from 'react';
import SelectDropdown from '../../../pages/common/select/select-dropdown';
// import Styles from './select-field.module.scss';
import formElementStyle from './form-elements.module.scss';
type selectFieldProps = {
  label: string;
  value: string;
  handleSelectChange: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  classNames?: string;
  options: { label: string; value: string }[];
  dataTestId?: string;
  suffixIcon?: ReactNode;
};

const SelectField = ({ value, label, handleSelectChange, options, disabled, placeholder, classNames = '', dataTestId, suffixIcon }: selectFieldProps): ReactElement => {
  return (
    <div className={`field ${classNames}`}>
      <span className={`label ${formElementStyle.label}`}>{label}</span>
      <div className={`control`}>
        <SelectDropdown
          style={{ width: '100%', borderRadius: '0.5rem' }}
          handleSelectDropdownChange={handleSelectChange}
          options={options}
          selectedValue={value}
          placeholder={placeholder}
          disabled={disabled}
          showSearch={true}
          optionFilterProp={'children'}
          filterOption={true}
          data-testid={dataTestId}
          suffixIcon={suffixIcon}
        />
      </div>
    </div>
  );
};

export default SelectField;
