import { defaultCampaignState, /*  actionsInSmartReplies, actionsInCommentModeration, actionsInBrandLift, */ allActionsSupported } from './config';
import { automationSubTabs, actionToAnalyticsMap, campaignTypes, analyticsKeys } from './constants';
import { v4 as uuid } from 'uuid';
import { AnalyticsObjUiTYpe, CampaignTypeUI, ResponseActionType } from './types';
import { PrivateReplyTags } from '../../common/entities/entities.types';
import { formatTagKey } from '../../common/entities/utils';
import { getFilteredActions } from '../../common/utils/platformActions';

type AnalyticsToshowUp = {
  potentialAnalyticsLabel: string;
  potentialAnalyticsCount: {
    total: number;
    mentions: number;
    comments: number;
  };
  organicPostCount: number;
  paidPostCount: number;
  replyEngaged?: {
    count: {
      total: number;
      mentions: number;
      comments: number;
    };
    label: string;
  };
};

export const getSubTabBasedOnPath = (pathName: string = '') => {
  let subTab = automationSubTabs.COMMENT_MODERATION;

  Object.entries(automationSubTabs).find(([_key, value]: [string, string]) => {
    if (pathName.includes(value)) {
      subTab = value;
      return true;
    }
    return false;
  });
  return subTab;
};

export const getDefaultCampaignData = (subTab: string, platform: string, actionPlatformConfig: any) => {
  let action = '';
  const actionOptions = getFilteredActions(platform, actionPlatformConfig, true, subTab, defaultCampaignState.createFor);
  action = actionOptions?.[0]?.value;
  const preparedState = {
    ...defaultCampaignState,
    actions: [{ type: action, id: uuid() }],
  };

  return preparedState;
};

export const getAnalyticsBasedAction = (analytics: AnalyticsObjUiTYpe | undefined, actions: ResponseActionType[] = []) => {
  const actionType = actions.find((action: ResponseActionType) => allActionsSupported.includes(action.type))?.type || '';

  const analyticsToConsider = actionToAnalyticsMap[actionType];
  const actionKey = (analyticsToConsider && analyticsToConsider.key) || '';
  const potentialAnalyticsLabel = (analyticsToConsider && analyticsToConsider.label) || '';
  const commentPaidPostCount = (analytics && analytics.paid && analytics.paid.comments?.[actionKey]) || 0;
  const mentionPaidPostCount = (analytics && analytics.paid && analytics.paid.mentions?.[actionKey]) || 0;

  const commentOrganiCount = (analytics && analytics.organic && analytics.organic.comments?.[actionKey]) || 0;
  const mentionorganicPostCount = (analytics && analytics.organic && analytics.organic.mentions?.[actionKey]) || 0;
  const organicPostCount = commentOrganiCount + mentionorganicPostCount;
  const paidPostCount = commentPaidPostCount + mentionPaidPostCount;

  const analyticsToshowUp: AnalyticsToshowUp = {
    potentialAnalyticsLabel,
    potentialAnalyticsCount: {
      total: organicPostCount + paidPostCount || 0,
      mentions: mentionPaidPostCount + mentionorganicPostCount,
      comments: commentPaidPostCount + commentOrganiCount,
    },
    organicPostCount: commentOrganiCount + mentionorganicPostCount,
    paidPostCount: commentPaidPostCount + mentionPaidPostCount,
  };

  if (actionKey === analyticsKeys.COMMENTS_REPLIED) {
    analyticsToshowUp.replyEngaged = {
      count: {
        total:
          getCountforPaidAndOrganicPost(analytics, analyticsKeys.COMMENTS_REPLIED_ENGAGED, 'comments') +
          getCountforPaidAndOrganicPost(analytics, analyticsKeys.COMMENTS_REPLIED_ENGAGED, 'mentions'),
        comments: getCountforPaidAndOrganicPost(analytics, analyticsKeys.COMMENTS_REPLIED_ENGAGED, 'comments'),
        mentions: getCountforPaidAndOrganicPost(analytics, analyticsKeys.COMMENTS_REPLIED_ENGAGED, 'mentions'),
      },
      label: 'Replies Engaged',
    };
  }
  return analyticsToshowUp;
};

const getCountforPaidAndOrganicPost = (analytics: AnalyticsObjUiTYpe | undefined, actionKey: string, commentOrMention: 'comments' | 'mentions') => {
  const commentPaidPostCount = (analytics && analytics.paid && analytics.paid[commentOrMention]?.[actionKey]) || 0;
  const commentOrganiCount = (analytics && analytics.organic && analytics.organic[commentOrMention]?.[actionKey]) || 0;
  return commentPaidPostCount + commentOrganiCount;
};

export const isStandardCampaign = (uiMetadata: { type: string } | undefined) => {
  if (!uiMetadata) {
    return false;
  }
  return uiMetadata.type === 'standard';
};

export const getCampaignTagsAsOptions = (campaignTags: PrivateReplyTags, campaigns: { [key: string]: CampaignTypeUI } = {}, currentCampaign: string | undefined) => {
  if (campaignTags) {
    const allCampaigns = Object.values(campaigns);
    return Object.keys(campaignTags).map((key: string) => ({
      label: campaignTags[key].name,
      value: campaignTags[key].name,
      disabled: isTagAlreadyUsedByCampaigns(campaignTags[key].name, allCampaigns, currentCampaign),
      action: campaignTags[key].actionType,
      key,
    }));
  }
  return [];
};

export const isTagAlreadyUsedByCampaigns = (tagName: string, allCampaigns: CampaignTypeUI[] = [], currentCampaign: string | undefined) => {
  if (allCampaigns.some((campaign: CampaignTypeUI) => campaign.selectedTag === tagName && campaign.id !== currentCampaign)) {
    return true;
  }
  return false;
};

export const getTaggingGCIfPaused = (currentCampaign: CampaignTypeUI, allCampaigns?: { [key: string]: CampaignTypeUI }, manageMode: boolean = false) => {
  const tagTargetedInCampaign = currentCampaign?.conditions?.childConditions?.[0]?.ifStatements?.[0]?.value;
  const campaignTagName = formatTagKey(manageMode ? currentCampaign.selectedTag || tagTargetedInCampaign : tagTargetedInCampaign || '');
  const campaigns = (allCampaigns && Object.values(allCampaigns)) || [];
  const taggingGC = campaigns.find((campaign: CampaignTypeUI) => campaign.type === campaignTypes.TAGGING && formatTagKey(campaign.name) === campaignTagName);
  if (taggingGC && taggingGC.paused) {
    return taggingGC;
  }
  return null;
};

export const getAutomationSubTabFromUrl = (url: string) => {
  const splitUrl = url.split('/');
  return splitUrl[splitUrl.length - 1];
};

export const getSubTabForGC = (gc?: any) => {
  const { brandLift, commentModeration, smartReplies } = gc?.campaignSegregated || {};

  if (brandLift && (brandLift.custom?.length || brandLift.recommended?.length)) return 'brandLift';
  else if (commentModeration && (commentModeration.custom?.length || commentModeration.recommended?.length)) return 'commentModeration';
  else if (smartReplies && (smartReplies.custom?.length || smartReplies.recommended?.length)) return 'smartReplies';
  return '';
};
