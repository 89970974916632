/* eslint-disable max-lines */
import moment from 'moment';
import { Comment, RawComment } from '../../pages/dashboard/components/comments-viewer/components/comment/comment.types';
import { AmplifyGeneratedEngagement, defaultCommentTags } from '../../pages/common/tag/tag.constants';
import { CommentTags, DisplayTagsKeysComments, SearchFilterTag, Tags } from '../../pages/common/tag/tag.types';
import { CommentUser, CommentUsers } from '../../pages/dashboard/components/comments-viewer/components/user/user.types';
import { logOut } from '../../pages/login/actions';
import { AmplifyError, AsyncThunkDispatch, EntityWithTags, ErrorType, User } from '../../types';
import { NotAuthorized, UnAuthorizedErrorRedirecting } from '../constants';
import { Comments, Post } from '../entities/entities.types';
import { setPageUIState } from '../page-ui-state/actions';
// import { InboxMessage, InboxMessagesUI } from '../../pages/inbox/types';
import { maxCommentBulkAction } from '../../pages/dashboard/components/comment-moderation/utility/config';
import { setErrorUserComment } from '../../common/entities/actions';
import { OverriddenErroMessages } from '../../pages/inbox/config';
import { isDeployedOnProd } from './prod-warning';
import { sendApiErrorEmailAndSlack } from '../modal/actions/async-actions';
import { isNumber } from 'lodash';
import { checkIfScheduledForPast, getIncludeExcludeSegmentsIds, getTagIdGroupNameAsKey } from '../../pages/broadcasts/utils';
import { broadcastScheduleConst, timezoneTypes } from '../../pages/broadcasts/constants';
import { GCChildCondition } from '../../types/api-calls';
import { v4 as uuid } from 'uuid';
import { BroadcastTypeUI } from '../../pages/broadcasts/types';

export const getScrollBarWidth = () => {
  const documentWidth = document.documentElement.clientWidth;
  const windowWidth = window.innerWidth;
  const scrollBarWidth = windowWidth - documentWidth;
  return scrollBarWidth;
};

export const disableBodyScrollBar = (shouldDisable: boolean) => {
  if (shouldDisable) {
    const scrollBarWidth = getScrollBarWidth();
    document.body.style.overflowY = 'hidden';
    document.body.style.width = `calc(100% - ${scrollBarWidth}px`;
  } else {
    document.body.style.overflowY = '';
    document.body.style.width = '100%';
  }
};

export const setCssVariable = (name?: string, value?: string) => {
  const propName = name || '--scrollbar-width';
  const propValue = value || `${getScrollBarWidth()}px`;
  const r = document.querySelector(':root') as HTMLElement;
  r?.style.setProperty(propName, propValue);
};
export function set100vhVar() {
  // If less than most tablets, set CSS var to window height.
  let value = '100vh';
  if (window.innerWidth <= 1024) {
    value = `${window.innerHeight}px`;
  }
  document.documentElement.style.setProperty('--real100vh', value);
}

export const filterUsingSearchFilterTags = (entities: EntityWithTags[], searchFilterTags: Tags, engagementTabName?: string, appliedPosts?: Post[]) => {
  if (!entities.length) return entities;
  if (searchFilterTags.amplifyEngaged[AmplifyGeneratedEngagement] && !engagementTabName) return [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { amplifyEngaged: removed, ...searchFilterDisplayTags } = searchFilterTags;
  const tagsKeys = Object.keys(searchFilterDisplayTags);
  const filteredEntities = entities.filter((entity: EntityWithTags) => {
    const { amplifyEngaged, ...entityTags } = entity.tags;
    if (
      engagementTabName &&
      searchFilterTags.amplifyEngaged[AmplifyGeneratedEngagement] &&
      !amplifyEngaged[AmplifyGeneratedEngagement]?.tabNamesForAmplifyEngaged?.[engagementTabName]
    ) {
      return false;
    }
    return tagsKeys.every((tagGroupKey: string) => {
      const searchFilterTagsInGroup = Object.keys(searchFilterDisplayTags[tagGroupKey as DisplayTagsKeysComments]);
      return searchFilterTagsInGroup.length
        ? searchFilterTagsInGroup.some((searchFilterTagId: string) => entityTags[tagGroupKey as DisplayTagsKeysComments]?.hasOwnProperty(searchFilterTagId))
        : true;
    });
  });
  if (appliedPosts && appliedPosts.length > 0) {
    const postIds = appliedPosts.map((post: Post) => post.id);
    const filtered = filteredEntities.filter((comment: any) => {
      return comment?.userComment?.postId ? postIds.includes(comment.userComment.postId) : true;
    });
    return filtered;
  }
  return filteredEntities;
};
export const addOrRemoveTag = (tag: SearchFilterTag, selectedTags: SearchFilterTag[], setSelectedTags: (selectedTags: SearchFilterTag[]) => void) => {
  selectedTags.find((selectedTag: SearchFilterTag) => selectedTag.id === tag.id)
    ? setSelectedTags(selectedTags.filter((selectedTag: SearchFilterTag) => selectedTag.id !== tag.id))
    : setSelectedTags([...selectedTags, tag]);
};

export const addOrRemoveComment = (comment: Comment, selectedUserComments: RawComment[], setSelectedUserComments: (selectedUserComment: RawComment[]) => void) => {
  if (selectedUserComments.find((selectedComment: RawComment) => selectedComment.commentId === comment.commentId)) {
    setSelectedUserComments(selectedUserComments.filter((selectedComment: RawComment) => selectedComment.commentId !== comment.commentId));
  } else {
    setSelectedUserComments([...selectedUserComments, comment.userComment]);
  }
};

export const singlePageCommentList = (
  page: number,
  commentsInSinglePage: Comment[],
  setCommentsInSinglePage: (commentsInSinglePage: Comment[]) => void,
  setCommentsPage: (page: number) => void,
) => {
  setCommentsInSinglePage(commentsInSinglePage);
  setCommentsPage(page);
};

export const postfilterSinglePageCommentList = (
  page: number,
  selectedPost: any,
  allComment: Comment[],
  setCommentsInSinglePage: (commentsInSinglePage: Comment[]) => void,
  setCommentsPage: (page: number) => void,
) => {
  const appliedPostsIds = selectedPost.map((post: Post) => post.id);
  const commentsInSinglePage: Comment[] = allComment.filter((comment: Comment) => appliedPostsIds.includes(comment?.userComment.postId));
  setCommentsInSinglePage(commentsInSinglePage);
  setCommentsPage(page);
};

export const setPreviousPageNumber = (page: number, setPreviousCommentsPage: (page: number) => void) => {
  setPreviousCommentsPage(page);
};

export const selectOrUnselectAllComments = (comments: Comment[], setSelectedUserComments: (isSelectedAllcomment: RawComment[]) => void) => {
  const rawComment: RawComment[] = [];
  // eslint-disable-next-line @typescript-eslint/typedef
  Object.values(comments).forEach((comment: Comment, index) => {
    if (index < maxCommentBulkAction) {
      const userComment = comment.userComment;
      rawComment.push(userComment);
    }
  });
  setSelectedUserComments(rawComment);
};

export const clearSelectedComment = (selectedUserComments: RawComment[], setSelectedUserComments: (selectedUserComment: RawComment[]) => void) => {
  setSelectedUserComments([...selectedUserComments]);
};

export const clearSiglePageComments = (setCommentsInSinglePage: (commentsInSinglePage: Comment[]) => void) => {
  const commentsInSinglePage: Comment[] = [];
  setCommentsInSinglePage(commentsInSinglePage);
};

export const setSelectAllComment = (selected: boolean, setSelectAll: (selected: boolean) => void) => {
  setSelectAll(selected);
};
export const showErrorModal = (error: any, dispatch: AsyncThunkDispatch, dataRefreshed?: boolean, message?: string, doNotRedirectToLogout?: boolean) => {
  let errorMessage = '';
  let isLogOut = false;
  if (error.response) {
    const { redirectToLoginMessage } = error.response.data || {};
    if (error.response.status === 401 || redirectToLoginMessage) {
      if (window.location.href.indexOf('login') === -1) {
        isLogOut = true;
        errorMessage = redirectToLoginMessage || UnAuthorizedErrorRedirecting;
      }
    } else if (error.response.status === 403) {
      errorMessage = NotAuthorized;
    } else if (error.response.status === 409) {
      errorMessage = dataRefreshed
        ? "This operations can't be allowed due to the stale data on the UI, so we have updated the data. Please Try again."
        : 'A conflict happened during this operation. Please try refreshing the page.';
    } else {
      // eslint-disable-next-line no-console
      //console.log('error.response', error.response);
      // eslint-disable-next-line no-console
      //console.log('error.response.data', error.response.data);
      errorMessage = error.response.data?.data?.errors?.length
        ? error.response.data?.data?.errors.reduce((prevErrorMessage: any, currenctError: any) => {
            if (prevErrorMessage) {
              prevErrorMessage = `${prevErrorMessage} \n;`;
            }
            const currentErrorMessage = OverriddenErroMessages[currenctError?.code] || currenctError.message;
            return prevErrorMessage + currentErrorMessage;
          }, errorMessage)
        : error.response.data?.message;
    }
    // The request was made and the server responded with a status code
    isDeployedOnProd() && dispatch(sendApiErrorEmailAndSlack(errorMessage, error));
  } else {
    errorMessage = error.message;
  }
  const errorProps: AmplifyError = {
    errorMessage: message || errorMessage,
    type: ErrorType.ERROR,
    isReportable: true,
    endPoint: error?.config?.url,
    description: error.response?.data?.data?.platformError?.message,
  };
  dispatch(setPageUIState({ key: 'error', value: errorProps }));
  if (!doNotRedirectToLogout && isLogOut) {
    setTimeout(() => {
      dispatch(logOut());
    }, 2000);
  }
  return errorProps;
};

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export function getTagsFromComments(comments: Comments) {
  const allCommentTags = defaultCommentTags();
  Object.values(comments).forEach((comment: Comment) => {
    const tags = comment.tags;
    Object.keys(tags).forEach((tagGroupKey: string) => {
      Object.values(tags[tagGroupKey as keyof CommentTags]).forEach((tag: SearchFilterTag) => {
        if (!allCommentTags[tagGroupKey as keyof CommentTags][tag.id]) {
          allCommentTags[tagGroupKey as keyof CommentTags][tag.id] = tag;
        }
      });
    });
  });
  return allCommentTags;
}

export function getTagsFromCommentUsers(users: CommentUsers) {
  const allCommentTags = defaultCommentTags();
  Object.values(users).forEach((user: CommentUser) => {
    const tags = user.tags;
    Object.keys(tags).forEach((tagGroupKey: string) => {
      Object.values(tags[tagGroupKey as keyof CommentTags]).forEach((tag: SearchFilterTag) => {
        if (!allCommentTags[tagGroupKey as keyof CommentTags][tag.id]) {
          allCommentTags[tagGroupKey as keyof CommentTags][tag.id] = tag;
        }
      });
    });
  });
  return allCommentTags;
}

export const preventDefault = (e: any) => {
  e.preventDefault();
};
export const sortOneArrayBasedOnAnotherArray = (itemsArray: any[], sortingArr: any[]) => {
  return itemsArray.sort((a: any, b: any) => {
    return sortingArr.indexOf(a) - sortingArr.indexOf(b);
  });
};

export const currentPageSelectedCommentCount = (currentPageComment: Comment[]) => {
  const comments: RawComment[] = [];
  for (let i = 0; i < currentPageComment.length; i++) {
    comments.push(currentPageComment[i].userComment);
  }
  return comments.sort();
};
export const handleSelectAllComments = (
  dispatch: AsyncThunkDispatch,
  selectedUserComments: RawComment[],
  commentsInSinglePage: Comment[],
  isSelectedAll: boolean,
  setSelectAll: (selected: boolean) => void,
  result: RawComment[],
  setSelectedUserComments: (isSelectedAllcomment: RawComment[]) => void,
) => {
  // eslint-disable-next-line no-console

  if (!isSelectedAll && selectedUserComments.length === 0) {
    if (result.length !== selectedUserComments.length) {
      setSelectAllComment(false, setSelectAll);
    } else {
      setSelectAllComment(true, setSelectAll);
    }
    commentsInSinglePage.length && selectOrUnselectAllComments(commentsInSinglePage, setSelectedUserComments);
  } else {
    if (result.length !== selectedUserComments.length) {
      dispatch(setPageUIState({ key: 'error', value: { errorMessage: `Bulk action support ` + maxCommentBulkAction + ` actions at a time!` } }));
    } else {
      if (isSelectedAll) {
        setSelectAllComment(false, setSelectAll);
        selectOrUnselectAllComments([], setSelectedUserComments);
      } else {
        setSelectAllComment(true, setSelectAll);
        commentsInSinglePage.length && selectOrUnselectAllComments(commentsInSinglePage, setSelectedUserComments);
      }
    }
  }
};

export const handleSelectAlluseEffect = (selectedUserComments: RawComment[], result: RawComment[], setSelectAll: (selected: boolean) => void, commentsInSinglePage: Comment[]) => {
  if (result.length > 0) {
    if (result.length === selectedUserComments.length && selectedUserComments.length === commentsInSinglePage.length) {
      setSelectAllComment(true, setSelectAll);
    } else {
      setSelectAllComment(false, setSelectAll);
    }
  } else {
    setSelectAllComment(false, setSelectAll);
  }
};

export const selectedCommentProcess = (
  dispatch: AsyncThunkDispatch,
  selectedUserComments: RawComment[],
  selectedComment: Comment,
  setSelectedUserComments: (isSelectedAllcomment: RawComment[]) => void,
  setSelectAll: (selected: boolean) => void,
  allcomments: Comments,
) => {
  if (selectedComment.userComment.errorMessage) {
    dispatch(setErrorUserComment(allcomments, [{ commentId: selectedComment.commentId, message: '' }]));
  }
  if (selectedUserComments.length === maxCommentBulkAction) {
    if (selectedUserComments.find((comment: RawComment) => selectedComment.commentId === comment.commentId)) {
      addOrRemoveComment(selectedComment, selectedUserComments, setSelectedUserComments);
      setSelectAllComment(false, setSelectAll);
    } else {
      dispatch(setPageUIState({ key: 'error', value: { errorMessage: `Bulk action support ` + maxCommentBulkAction + ` actions at a time!` } }));
    }
  } else {
    addOrRemoveComment(selectedComment, selectedUserComments, setSelectedUserComments);
    setSelectAllComment(false, setSelectAll);
  }
};

export const errorReportMailBody = (uerId: string, Email: string, endPoint: string, errorMessage: string, requestId?: string, requestData?: string) => {
  const mailBody = `<html>
   <head>
      <title></title>
      <link href='https://svc.webspellchecker.net/spellcheck31/lf/scayt3/ckscayt/css/wsc.css' rel='stylesheet' type='text/css' />
   </head>
   <body data-gr-ext-installed='' data-new-gr-c-s-check-loaded='14.1050.0'>
   <b>Error Message :</b> ${errorMessage}        
   <br /><br /> 
   ${
     requestId
       ? `  
    <b>Request Id : </b> ${requestId}
   <br /> <br />    `
       : ''
   } 
   ${
     requestData
       ? `  
    <b>Request Data (Params) : </b> ${requestData}
   <br /> <br /> `
       : ''
   }
   <b>End point : </b> ${endPoint}
   <br /> <br />        
   <b>Logged In User : </b> ${Email} (${uerId})
   </body>
</html>`;
  return mailBody;
};

export const checkBlackListError = (errorMessage: string, blockedErrorList: string[]) => {
  return blockedErrorList.some((blockedError: string) => {
    return errorMessage.toLowerCase().includes(blockedError.toLowerCase());
  });
};

export const checkForConfirmationDialogBox = (currentUser: User) => {
  return currentUser && currentUser.roles && ['SuperAdmin', 'BotworxAdmin'].includes(currentUser.roles);
};
export const isValidUrl = (str: string = '') => {
  ///^(?:(?:https?:\/\/)|(?:www\.))[-A-Za-z0-9+&@#\/%?=~_|!:,.;]*\.[A-Za-z]+(?:\.[A-Za-z]+)?\b/;
  const urlRegex = /^(?:(?:https?:\/\/)|(?:www\.))[-A-Za-z0-9+&@#/%?=~_|!:,.;]*\.[A-Za-z]+(?:\.[A-Za-z]+)?\b/;
  const isValidUrl = str && urlRegex.test(str);

  return !!isValidUrl;
};

export const formatOptionalValue = (value?: number | string, fallbackValue?: string) => {
  if (typeof value === 'string' && value) {
    return value;
  }
  if (typeof value === 'number' && isNumber(value)) {
    return value;
  }
  return fallbackValue || '-';
};

export function fetchBroadcastDateLocally(broadcastDate?: any, broadcastTimeZone?: string) {
  const localTimeZone = moment.tz.guess();
  const locallyNow = moment.tz(localTimeZone);
  const scheduledDateLocally = broadcastDate?.tz(localTimeZone);
  const localAndAccountTimesDiffer = localTimeZone && broadcastTimeZone && moment.tz(localTimeZone).utcOffset() !== moment.tz(broadcastTimeZone).utcOffset();

  return {
    localTimeZone,
    locallyNow,
    relativeTime: scheduledDateLocally?.fromNow(),
    scheduledDateLocally: scheduledDateLocally.format('DD MMM YYYY'),
    scheduledTimeLocally: scheduledDateLocally.format(' HH:mm (z)'),
    localAndAccountTimesDiffer,
  };
}

export const getTransformedScheduleStartDate = (startDate: any, timezone: string, timeZoneType: string) => {
  const nowInTimeZone = moment().tz(timezone).add(1, 'minutes');
  const isPastOrCurrent = checkIfScheduledForPast(startDate, timezone, timeZoneType) || startDate < nowInTimeZone;

  if (!isPastOrCurrent) {
    return startDate;
  }

  return nowInTimeZone?.add(1, 'minutes');
};

export const getUpdatedBroadcastStartDate = (startDateVal: number, currentTimezoneType: string, currentTimeZone: string) => {
  const currentDate = moment.tz(currentTimeZone);
  const accountOffset = moment.tz(currentTimeZone).utcOffset();
  let startDateFinal = currentTimezoneType === timezoneTypes.USER ? moment(startDateVal).utcOffset(accountOffset, true) : moment(startDateVal).tz(currentTimeZone);
  const broadcastDate = startDateFinal.utcOffset(accountOffset, true);
  if (broadcastDate <= currentDate) {
    startDateFinal = currentDate.add(2, 'minutes');
  }
  return { broadcastDate, currentDate, startDateFinal };
};

export const getBroadcastStartDateTransformedToApi = (broadcast: BroadcastTypeUI, timezone: string) => {
  let dateObj;
  if (broadcast.date && broadcast.time) {
    if (broadcast['timeZone'] === 'bot') {
      dateObj = moment.utc(broadcast.date + ' ' + broadcast.time, 'YYYY-MM-DD HH:mm').tz(timezone);
    } else {
      dateObj = moment(broadcast.date + ' ' + broadcast.time);
    }
  } else {
    dateObj = moment();
  }
  const { broadcastDate, currentDate, startDateFinal: startDate } = getUpdatedBroadcastStartDate(dateObj.valueOf(), broadcast?.timeZone, timezone);

  if (broadcastDate > currentDate) {
    return { date: broadcast?.date, time: broadcast?.time, type: broadcastScheduleConst.LATER.value };
  }

  let utcDate: any = startDate;
  if (broadcast['timeZone'] === 'bot') {
    const userDate = moment(startDate).format('YYYY-MM-DD HH:mm');
    utcDate = moment.tz(userDate, timezone).utc();
  }
  return { date: utcDate?.format('YYYY-MM-DD'), time: utcDate?.format('HH:mm'), type: broadcastScheduleConst.LATER.value };
};

export const getBroadcastSegementDetails = (broadcast: BroadcastTypeUI) => {
  let advancedSegmentConditions: any;
  const broadcastChildCondtions: any = [];
  broadcast.conditions?.childConditions?.forEach((childC: GCChildCondition) =>
    childC.childConditions?.forEach((childConditionSegment: any) => {
      if (childConditionSegment.segmentId || childConditionSegment.tagId) {
        broadcastChildCondtions.push(childConditionSegment);
      }
    }),
  );
  const { includeSegments, excludeSegments } = getIncludeExcludeSegmentsIds(broadcastChildCondtions);
  // callbacks.setSelectedSegmentIds({ included: includeSegments, excluded: excludeSegments });
  if (broadcast.hasAdvancedSegments) {
    const segmentConditions: any = [];
    broadcast?.conditions &&
      broadcast?.conditions.childConditions &&
      broadcast?.conditions.childConditions.forEach((childC: any) => {
        if (childC.segmentId !== undefined) {
          const allChildCWithIds = childC?.childConditions?.map((condition?: any) => {
            const conditionWithSegmentId = { ...condition, id: uuid() };
            if (condition.tagId) {
              conditionWithSegmentId.segmentId = getTagIdGroupNameAsKey(condition.tagId, condition.tagGroupName);
              delete conditionWithSegmentId.tagId;
              delete conditionWithSegmentId.tagGroupName;
            }
            return conditionWithSegmentId;
          });
          segmentConditions.push({ ...childC, childConditions: allChildCWithIds, id: uuid() });
        }
      });

    advancedSegmentConditions = { childConditions: segmentConditions, logicalOperator: broadcast?.conditions?.logicalOperator };
  }

  return { advancedSegmentConditions, selectedSegmentIds: { included: includeSegments, excluded: excludeSegments } };
};

export const getBroadcastTestDetails = (broadcast: BroadcastTypeUI, timeZone: string) => {
  const { testDate, testedBy } = broadcast?.testBroadcastInfo || {};

  if (!testDate) {
    return {
      broadcastTestedBy: testedBy?.name,
    };
  }

  const utcTestDate = testDate && moment.utc(testDate, 'YYYY-MM-DD HH:mm');
  const {
    scheduledDateLocally: broadcastTestDate,
    scheduledTimeLocally: broadcastTestTime,
    localAndAccountTimesDiffer: showTestDateTooltip = true,
    relativeTime: relativeTestTime,
  }: any = utcTestDate ? fetchBroadcastDateLocally(utcTestDate, timeZone) : {};
  const testDateInBotTimeZone = showTestDateTooltip && utcTestDate ? utcTestDate.tz(timeZone).format('DD MMM YYYY hh:mmA (z)') : '';

  return {
    broadcastTestedBy: testedBy?.name,
    broadcastTestDate,
    broadcastTestTime,
    relativeTestTime,
    testDateInBotTimeZone,
  };
};

export const validateDateRangeNotMoreThan3YearsAgo = (startDate: string, endDate: string) => {
  // Validate and adjust startDate and endDate
  const threeYearsAgo = moment().subtract(3, 'years').startOf('day');
  let startDateMoment = moment(startDate, 'YYYY-MM-DD');
  let endDateMoment = moment(endDate, 'YYYY-MM-DD');

  if (startDateMoment.isBefore(threeYearsAgo)) {
    startDateMoment = threeYearsAgo;
  }

  if (!endDateMoment.isAfter(startDateMoment)) {
    endDateMoment = startDateMoment;
  }

  return { startDate: startDateMoment.format('YYYY-MM-DD').replace(/-/g, ''), endDate: endDateMoment.format('YYYY-MM-DD').replace(/-/g, '') };
};
