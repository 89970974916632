import { createAction } from '@reduxjs/toolkit';
import { Audiencedata, MessageUsageUI } from '../types';
import constants from './constants';
import { broadcastConstant } from '../constants';

export const storeMessageUsage = createAction<MessageUsageUI>(constants.STORE_MESSAGE_USAGE);
export const setFetchingMessageUsage = createAction<boolean>(constants.SET_FETCHING_MESSAGR_USAGE);
export const storeAudiencedata = createAction<Audiencedata>(constants.STORE_AUDIENCE_DATA);
export const setCreateBroadcastName = createAction<string>(broadcastConstant.CREATE_BROADCAST_NAME);
export const setReplyContent = createAction<string>(broadcastConstant.SET_BROADCAST_RESPONSE);
export const setCurrentActiveTab = createAction<string>(broadcastConstant.SET_CURRENT_ACTIVE_TAB);
export const setDeletedBroadcastFetched = createAction<boolean>(broadcastConstant.SET_DELETED_BROADCAST_FETCHED);
export const setTestBroadcastSent = createAction<boolean>(broadcastConstant.SET_TEST_BROADCAST_SENT);
export const setTestLink = createAction<string>(broadcastConstant.FETCH_TEST_LINK);
export const setNotificationLabels = createAction<{ [key: string]: any }>(broadcastConstant.FETCH_NOTIFICATION_LABELS);
export const setFetchingDonationAmount = createAction<boolean>(constants.SET_FETCHING_DONATION_AMOUNT);
export const storeDonationAmount = createAction<any>(constants.STORE_DONATION_AMOUNT);
