import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { registerAmplifyAnalytics } from './analytics/utils';
import { useCurrentAccountId, useCurrentUser } from './hooks';
import { getRouterTitle, setDocumentTitle } from './utils/path-to-title';
import { routeToPageTitle } from './config/route-page-title';
import { IsExternalUser } from './common/utils/external-user';
import { LoginComponent, AppLyoutComponent, GeneralComponent, AdminToolComponent, PageAccessComponent } from './lazyloading/lazyLoadingConstant';
import PrivateRoute from './common/non-render/private-route';
import { Spinner } from './common/spinner';
import { pathsDontNeedAccountAccountSelection } from './common/page-ui-state/constants';
import { getRedirectPath } from './utils';
import { useDispatch } from 'react-redux';
import { showErrorModal } from './common/utils';
import { useIsAmplifyAccountOwner, useIsBotworxAdminUser } from './pages/user-profile-pages/hooks';
import { useSystemPermissions } from './common/role-level-permission/hooks/permission';
import { permissionEntities, permissionType } from './common/role-level-permission/permission.constants';
import InvitationAccepted from './pages/triller';
import OptOutPage from './pages/save-tiktok/opt-out';

const Routes = () => {
  const location = useLocation();
  const accountId = useCurrentAccountId();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const isBotworxAdmin = useIsBotworxAdminUser();
  const isAmplifyAccountOwner = useIsAmplifyAccountOwner();
  const hasViewBMPagePermission = useSystemPermissions(permissionEntities.SYSTEM, permissionType.VIEW_BM_PAGES);
  useEffect(() => {
    if (accountId && user) {
      registerAmplifyAnalytics.setAccountUserIdInConfig(accountId, user.id, IsExternalUser(user.email));
    }
  }, [accountId, user]);
  useEffect(() => {
    const routerTitle = getRouterTitle(location.pathname, routeToPageTitle);
    location.pathname && setDocumentTitle(routerTitle);
    // TODO: Remove below console.
    if (location.hash || location.search) {
      const locationString = location.hash?.includes('code') ? location.hash.replace('#', '?') : location.search;
      const urlParams = new URLSearchParams(locationString);
      const accessCodeExtracted = urlParams.get('code');
      const originalRedirectPath: any = getRedirectPath(`${urlParams.get('state') || ''}`);
      const error: any = urlParams.get('error');
      !!error && showErrorModal(error, dispatch);
      originalRedirectPath && (window.location.href = `${window.location.origin}${originalRedirectPath}${accessCodeExtracted ? `?code=${accessCodeExtracted}` : ''}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.hash]);
  /* For debugging route changes */
  // const history = useHistory();
  // useEffect(() => {
  //   logger.log('initial history is: ', JSON.stringify(history));
  //   history.listen(() => {
  //     const { location } = history;
  //     logger.log(`The current URL is ${location.pathname}${location.search}${location.hash}`);
  //     logger.log(JSON.stringify(history));
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route path='/trillerInvite' component={InvitationAccepted} />
        <Route path='/login' component={LoginComponent} />
        {(isBotworxAdmin || isAmplifyAccountOwner) && <PrivateRoute path={pathsDontNeedAccountAccountSelection.adminTools} component={AdminToolComponent} />}
        {(isBotworxAdmin || (isAmplifyAccountOwner && hasViewBMPagePermission)) && (
          <PrivateRoute path={pathsDontNeedAccountAccountSelection.requestPageAccess} component={PageAccessComponent} />
        )}
        <Route
          path='/(platform-login|register|forgot-password|reset-password|password-reset-success|chooseAccount|twitterCallback|verifyOtp|enableMfa)'
          component={GeneralComponent}
        />
        <PrivateRoute path={pathsDontNeedAccountAccountSelection.saveMySocialsOptOut} component={OptOutPage} />

        <PrivateRoute path='/' component={AppLyoutComponent} />
        <Redirect from='*' to='/' />
      </Switch>
    </Suspense>
  );
};
export default Routes;
