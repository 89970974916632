import { Checkbox, CheckboxProps } from 'antd';
import React, { ReactNode } from 'react';
import styles from './checkbox-style.module.scss';
import classnames from 'classnames';

export type AmplifyCheckBoxProps = {
  handleCheckboxChange?: (fieldName: string) => void;
  checked: boolean;
  fieldName: string;
  label?: ReactNode;
  checkboxType?: 'circle';
  size?: 'small' | 'default';
  testId?: string;
  labelClassName?: string;
} & CheckboxProps;

const AmplifyCheckBox = (props: AmplifyCheckBoxProps) => {
  const { handleCheckboxChange, checked, fieldName, label = '', size, checkboxType, testId, labelClassName = 'fieldName', ...rest } = props;

  return (
    <Checkbox
      onChange={() => handleCheckboxChange && handleCheckboxChange(fieldName)}
      checked={checked}
      {...rest}
      className={classnames({ [styles.circle]: checkboxType === 'circle' }, { [styles.small]: size === 'small' })}
      data-testid={testId}
    >
      {label && <span className={labelClassName}>{label}</span>}
    </Checkbox>
  );
};

export default AmplifyCheckBox;
