export enum TrillerInviteBotTypes {
  TrillerCommunity = 'triller_community',
  AmplifyTier2 = 'amplify_tier_2',
}

export type TrillerInvite = {
  accountId: string;
  botId: string;
  accessToken: string;
  userId: string;
  platform: string;
  accountName: string;
  botName: string;
  emailId: string;
  botType?: TrillerInviteBotTypes;
};
