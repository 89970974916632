import React, { ReactElement, ChangeEvent, ReactNode } from 'react';
import Styles from './form-elements.module.scss';
import classNames from 'classnames';
import { Input } from 'antd';
import SelectDropdown from '../../../pages/common/select/select-dropdown';
import { SelectOption } from '../../../types';

type InputProps = {
  name: string;
  handleInputChange?: (e: ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLTextAreaElement>) => void;
  invalid?: boolean;
  value?: string | number;
  placeholder?: string;
  type?: string;
  inputLabel?: React.ReactNode | string;
  inputClassName?: string;
  className?: string;
  invalidMessage?: ReactNode;
  options?: SelectOption[];
  [key: string]: any;
};

const InputWithLabel = ({
  name,
  handleInputChange,
  invalid,
  value,
  placeholder,
  inputLabel,
  className = '',
  inputClassName = '',
  invalidMessage,
  ...rest
}: InputProps): ReactElement => {
  const ComponentToUse: any = rest.type === 'textarea' ? Input.TextArea : rest.type === 'selectdropdown' ? SelectDropdown : Input;
  return (
    <div className={`field ${className}`}>
      {inputLabel && <label className={classNames(Styles.label, 'label')}>{inputLabel}</label>}
      <div className='control'>
        <ComponentToUse
          className={`${Styles.amplifyInput} ${inputClassName} ${invalid ? 'is-danger' : ''}`}
          {...{ name, value, placeholder }}
          {...rest}
          onChange={handleInputChange}
        />
        {invalidMessage && <p className={`help is-danger ${Styles.invalidText}`}>{invalidMessage}</p>}
      </div>
    </div>
  );
};

export default InputWithLabel;
