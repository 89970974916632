import { showMessage } from 'src/pages/common/toast-notification';
import { put } from '../../../common/api-calls/api-client';
import { storeUserAccount } from '../../../common/entities/actions';
import { UserAccount } from '../../../common/entities/entities.types';
import { showErrorModal } from '../../../common/utils';
import { AsyncThunkDispatch, RootState, Thunk } from '../../../types';

export const updateAccount = (account: UserAccount, done?: () => void, doNotShowToastMessage: boolean = true): Thunk => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState): Promise<void> => {
    const url = 'accounts';
    const state = getState();
    const originalAccount = state.entities?.account;
    const accountToUpdate = { ...originalAccount, ...account };
    accountToUpdate.lastUpdatedDate = originalAccount?.lastUpdatedDate;
    const { auth } = getState();
    const authToken = auth.authToken;
    let response = null;
    try {
      response = await put(url, accountToUpdate, authToken as string);
      if (response) {
        accountToUpdate.lastUpdatedDate = response.headers['x-response-time'];
        done && done();
        doNotShowToastMessage && showMessage('Settings updated.');
        dispatch(storeUserAccount(accountToUpdate));
      }
    } catch (error: any) {
      showErrorModal(error, dispatch);
    }
  };
};
