const broadcastConstants: BroadcastConstants = {
  STORE_MESSAGE_USAGE: 'STORE_MESSAGE_USAGE',
  STORE_AUDIENCE_DATA: 'STORE_AUDIENCE_DATA',
  SET_FETCHING_MESSAGR_USAGE: 'SET_FETCHING_MESSAGR_USAGE',
  SET_FETCHING_DONATION_AMOUNT: 'SET_FETCHING_DONATION_AMOUNT',
  STORE_DONATION_AMOUNT: 'STORE_DONATION_AMOUNT',
};
type BroadcastConstants = {
  STORE_MESSAGE_USAGE: string;
  STORE_AUDIENCE_DATA: string;
  SET_FETCHING_MESSAGR_USAGE: string;
  SET_FETCHING_DONATION_AMOUNT: string;
  STORE_DONATION_AMOUNT: any;
};
export default broadcastConstants;
