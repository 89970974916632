/* eslint-disable max-lines-per-function */

import React, { useState } from 'react';
import { Card } from 'antd';
import styles from './opt-out.module.scss';
import { GenericAntModal } from 'src/common/modal/components';
import AmplifyCheckBox from '../common/buttons/chekbox';
import { Input } from 'src/common/components/form-elements';
import { useDispatch } from 'react-redux';
import { optOutStt } from './async-actions/actions';
import { validateEmail } from 'src/common/utils/validations';

const OptOutPage = () => {
  const [confirm, setConfirm] = useState(false);
  const [optedOut, setOptedOut] = useState(false);
  const [reason, setReason] = useState('');
  const [checked, setChecked] = useState({} as any);
  const dispatch = useDispatch();

  const handleOptOut = (name: string) => {
    setChecked({ [name]: !checked[name] });
  };
  const resetState = () => {
    setReason('');
    setChecked({});
    setOptedOut(false);
  };

  const handleOptOutConfirm = async () => {
    setConfirm(false);
    const isEmail = validateEmail(reason);
    const key = isEmail ? 'emailId' : 'username';

    const optedOut: any = await dispatch(optOutStt({ [key]: reason, deleteVideos: checked['optOut'] }));
    if (optedOut) {
      setOptedOut(true);
    }
  };
  const isValid = (field: string | undefined, fieldName?: string) => {
    switch (fieldName) {
      default:
        return !!(field && field.length);
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        {!optedOut ? (
          <>
            <h2 className={styles.title}>Opt-Out of Save My Socials</h2>

            <p className={styles.description}>User can opt out of receiving emails and notifications from us. They can always opt back in later.</p>
            <Input
              inputLabel='Communication Email or Tiktok UserName'
              name='Enter email or tiktok username'
              placeholder='Please Communication Email or Tiktok UserName'
              invalid={!isValid(reason)}
              value={reason}
              handleInputChange={(e: any) => setReason(e.target.value)}
            />
            <p className={styles.deleteCheck}>
              <AmplifyCheckBox label={'Delete Uploaded Videos'} fieldName='optOut' handleCheckboxChange={handleOptOut} checked={checked['optOut']} />
            </p>
            <button className={'button is-primary is-fullwidth is-login-large'} onClick={() => setConfirm(true)} style={{ marginTop: '1rem', fontSize: '14px' }} disabled={!reason}>
              Opt-Out
            </button>
          </>
        ) : (
          <div className={styles.successMessage}>
            <h3 className={styles.successTitle}>{`You've successfully opted out "${reason}"`}</h3>
            <p className={styles.successDescription}>{`The user won't receive further notifications. If they change their mind, they can opt back in anytime.`}</p>
            <button onClick={resetState} className='close-btn button is-primary' style={{ marginTop: '1rem', fontSize: '14px' }}>
              Opt out More?
            </button>
          </div>
        )}
      </Card>
      <GenericAntModal title='Confirm Opt-Out' visible={confirm} onCancel={() => setConfirm(false)} okButtonProps={{ danger: true }} mask={true} onOk={handleOptOutConfirm}>
        <div className={styles.modalContent}>
          <p>Are you sure you want to opt out?</p>
        </div>
        {/* <div className='is-flex m-t-10' style={{ justifyContent: 'flex-end' }}>
          <button onClick={() => setConfirm(false)} className='close-btn button' style={{ marginRight: '10px' }}>
            Cancel
          </button>
          <button onClick={handleOptOutConfirm} className='close-btn button is-primary' disabled={!reason}>
            Confirm
          </button>
        </div> */}
      </GenericAntModal>
    </div>
  );
};

export default OptOutPage;
